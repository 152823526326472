import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 225 225" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="" >
				<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M226.000000,91.000000 
	C226.000000,95.689255 226.000000,100.378510 225.534973,105.534149 
	C221.765472,106.001846 218.458801,106.078911 215.157242,105.981781 
	C212.232498,105.895737 210.905136,107.133377 210.983032,110.099579 
	C211.078445,113.731873 210.999756,117.368744 210.998169,121.001816 
	C211.000000,121.000000 211.003662,120.996460 210.537140,120.998528 
	C205.378693,121.002136 200.686752,121.003677 195.996597,120.538467 
	C196.001358,115.367767 196.004349,110.663826 196.003662,105.726532 
	C195.999969,105.493187 195.993958,105.026489 196.377472,105.187134 
	C200.493546,105.553123 204.245605,106.042488 207.946548,105.788025 
	C209.034088,105.713249 210.706390,103.608002 210.846954,102.297775 
	C211.246872,98.569382 210.995209,94.771088 211.466125,91.002083 
	C216.621490,91.002571 221.310745,91.001282 226.000000,91.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M46.000000,226.000000 
	C41.310745,226.000000 36.621490,226.000000 31.466295,225.533875 
	C31.000187,220.378510 31.000017,215.689255 31.464903,211.001053 
	C35.258274,211.000153 38.587765,210.942886 41.914524,211.013885 
	C44.534351,211.069809 45.890751,210.099167 46.040440,207.284546 
	C46.229649,203.726868 45.512882,198.559952 47.471119,197.050491 
	C49.947853,195.141357 54.724270,196.208572 58.509731,196.009735 
	C59.339691,195.966156 60.173557,195.997040 61.003563,196.460464 
	C60.999012,201.620178 60.996521,206.313080 60.997101,211.002991 
	C61.000164,211.000000 61.006367,210.993637 60.540703,210.994476 
	C56.770554,210.999298 53.463734,211.081970 50.162296,210.983688 
	C47.237076,210.896591 45.905632,212.130035 45.982166,215.095825 
	C46.075912,218.728287 46.001633,222.365082 46.000000,226.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M166.000000,226.000000 
	C161.310745,226.000000 156.621490,226.000000 151.466248,225.533875 
	C151.000183,220.378510 151.000092,215.689255 151.465042,211.001190 
	C155.258362,211.000473 158.587830,210.943405 161.914551,211.014221 
	C164.534073,211.069977 165.890961,210.099426 166.040741,207.284851 
	C166.230072,203.727219 165.513290,198.560150 167.471451,197.050949 
	C169.948273,195.142075 174.724548,196.209793 178.509964,196.011246 
	C179.339935,195.967728 180.173767,195.998352 181.003479,196.461288 
	C180.998672,201.620636 180.996216,206.313538 180.996887,211.003220 
	C181.000000,211.000000 181.006439,210.993729 180.540771,210.994598 
	C176.770630,210.999420 173.463806,211.082138 170.162399,210.983795 
	C167.237137,210.896652 165.905792,212.130173 165.982269,215.095871 
	C166.075943,218.728317 166.001663,222.365097 166.000000,226.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M1.000000,46.000000 
	C1.000000,41.310745 1.000000,36.621490 1.465029,31.465080 
	C5.233344,30.996979 8.538732,30.922588 11.839283,31.017103 
	C14.754831,31.100597 16.104002,29.876181 16.017359,26.904680 
	C15.921126,23.604317 16.002041,20.298786 16.002594,16.998173 
	C15.999363,17.000839 15.993869,17.008253 16.375124,16.840014 
	C21.172434,16.459425 25.588491,16.247078 30.111713,16.246494 
	C30.415308,16.721550 30.670603,16.901196 30.991577,17.462082 
	C30.998848,22.284571 30.999310,26.642172 30.534760,30.997955 
	C26.766685,30.999792 23.461502,31.078255 20.161196,30.984671 
	C17.245258,30.901985 15.903681,32.124882 15.982459,35.096382 
	C16.078712,38.727100 15.999283,42.362476 15.529970,45.995949 
	C10.376077,45.997372 5.688038,45.998688 1.000000,46.000000 
z"/>
<path fill="#FEFFFF" opacity="1.000000" stroke="none" 
	d="
M136.000000,1.000000 
	C140.689255,1.000000 145.378510,1.000000 150.534302,1.467253 
	C151.151337,6.344116 149.618332,12.598893 151.896027,14.677012 
	C154.501297,17.053999 160.493851,15.719465 165.014862,15.993449 
	C165.342606,16.013309 165.672394,15.999370 166.000610,16.000572 
	C166.000000,16.000000 165.998779,15.998712 166.000168,16.464989 
	C166.003052,21.622705 166.004547,26.314148 165.557541,31.005995 
	C162.712051,31.015671 160.315079,31.024942 157.685043,30.933395 
	C157.303757,30.750839 157.155533,30.669096 157.008057,30.260525 
	C156.999405,29.279194 156.989975,28.624687 156.961182,27.680538 
	C156.629776,26.939871 156.317719,26.488846 155.993042,25.642796 
	C154.328476,24.496454 152.676544,23.745136 151.018738,22.569324 
	C151.094330,19.469397 151.385956,16.333481 147.665970,16.201284 
	C143.790970,16.063580 139.888580,16.696716 135.998474,16.743170 
	C135.998795,16.493523 135.997559,15.994229 135.995300,15.528477 
	C135.995361,10.375150 135.997681,5.687575 136.000000,1.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M1.000000,166.000000 
	C1.000000,161.310745 1.000000,156.621490 1.465029,151.465057 
	C5.233333,150.996902 8.538711,150.922516 11.839250,151.017044 
	C14.754851,151.100555 16.103912,149.876038 16.017286,146.904587 
	C15.921071,143.604233 16.001974,140.298721 16.002567,136.998138 
	C15.999371,137.000839 15.993845,137.008224 16.370127,136.842224 
	C20.164440,136.457077 23.582474,136.237946 27.000305,136.469177 
	C27.333603,141.605484 27.667097,146.291397 27.541628,150.983932 
	C25.266920,150.994705 23.432562,151.167496 21.639143,150.969467 
	C17.330692,150.493790 15.559757,152.210464 15.947962,156.591629 
	C16.223682,159.703308 15.997868,162.859436 15.530042,165.995911 
	C10.376121,165.997391 5.688060,165.998688 1.000000,166.000000 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M106.000000,226.000000 
	C101.310745,226.000000 96.621490,226.000000 91.465012,225.533890 
	C90.998833,220.378922 90.999878,215.690079 91.465836,211.003021 
	C92.918236,211.004150 93.905830,211.013474 94.893173,211.001266 
	C107.465805,210.845657 105.898293,212.632614 106.451523,199.961334 
	C111.608864,199.927460 116.307487,199.986343 121.000633,200.500275 
	C120.995697,204.304535 120.996231,207.653763 120.998383,211.001495 
	C121.000000,211.000000 121.003052,210.996948 120.537712,210.999512 
	C116.768234,211.002899 113.461998,211.077133 110.160583,210.982590 
	C107.243706,210.899063 105.901978,212.125259 105.981583,215.096954 
	C106.078888,218.728867 106.001717,222.365448 106.000000,226.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M30.984768,16.997192 
	C30.670603,16.901196 30.415308,16.721550 30.275549,15.859869 
	C30.554811,10.507655 30.777407,5.753828 31.000000,1.000000 
	C35.689255,1.000000 40.378513,1.000000 45.533394,1.466125 
	C45.999908,6.622062 46.000793,11.311872 45.537937,15.999182 
	C40.377720,16.330185 35.681244,16.663689 30.984768,16.997192 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M1.000000,136.000000 
	C1.000000,131.310745 1.000000,126.621490 1.466146,121.466415 
	C6.623446,120.998489 11.314599,120.996384 16.004166,121.461777 
	C15.999669,126.955589 15.996758,131.981903 15.993845,137.008224 
	C15.993845,137.008224 15.999371,137.000839 15.620428,136.830811 
	C10.494323,136.440521 5.747162,136.220261 1.000000,136.000000 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M1.000000,16.000000 
	C1.000000,11.075478 1.000000,6.150956 1.000000,1.000000 
	C5.683317,1.000000 10.368995,1.000000 15.527845,1.466867 
	C15.998634,6.958574 15.996252,11.983414 15.993870,17.008253 
	C15.993869,17.008253 15.999363,17.000839 15.620448,16.830841 
	C10.494355,16.440561 5.747178,16.220282 1.000000,16.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M91.000000,1.000000 
	C95.689255,1.000000 100.378510,1.000000 105.534119,1.466245 
	C105.998306,6.630046 105.996132,11.327603 105.996872,16.262335 
	C105.999779,16.499510 106.005287,16.974482 105.620911,16.815094 
	C100.490005,16.437893 95.743477,16.220079 90.998856,15.536009 
	C91.000511,10.379836 91.000252,5.689918 91.000000,1.000000 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M226.000000,90.533882 
	C221.310745,91.001282 216.621490,91.002571 211.466125,91.001930 
	C211.000000,91.000000 211.000000,90.999695 210.997589,90.533615 
	C210.995270,85.376823 210.995361,80.686119 211.461853,75.997223 
	C216.618835,75.999359 221.309418,75.999680 226.000000,76.000000 
	C226.000000,80.689255 226.000000,85.378510 226.000000,90.533882 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M16.005753,120.994270 
	C11.314599,120.996384 6.623446,120.998489 1.466146,121.000305 
	C1.000000,116.310745 1.000000,111.621490 1.466146,106.466309 
	C6.623351,105.993050 11.314410,105.985710 16.004076,106.446960 
	C15.999866,111.612312 15.997048,116.309067 15.996893,121.003143 
	C15.999555,121.000465 16.005753,120.994270 16.005753,120.994270 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M91.000916,211.001221 
	C90.999878,215.690079 90.998833,220.378922 90.998901,225.533890 
	C86.310745,226.000000 81.621490,226.000000 76.465332,225.533875 
	C75.998856,220.378510 75.999275,215.689255 75.999847,211.000000 
	C76.000000,211.000000 76.000290,211.000000 76.465912,210.997650 
	C81.620834,210.996689 86.310127,210.998047 90.999710,210.999710 
	C91.000000,211.000000 91.000916,211.001221 91.000916,211.001221 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M136.000000,226.000000 
	C131.310745,226.000000 126.621490,226.000000 121.466362,225.533875 
	C121.001343,220.377487 121.002197,215.687210 121.003052,210.996948 
	C121.003052,210.996948 121.000000,211.000000 121.464874,210.998978 
	C126.622055,210.996735 131.314377,210.995514 136.003189,211.460754 
	C135.999802,216.618149 135.999908,221.309067 136.000000,226.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M16.005470,105.978378 
	C11.314410,105.985710 6.623351,105.993050 1.466146,106.000191 
	C1.000000,101.310745 1.000000,96.621490 1.466149,91.465149 
	C6.623693,90.996567 11.315088,90.995056 16.002787,91.458931 
	C15.997799,96.288857 15.996509,100.653404 15.997364,105.258057 
	C15.999511,105.498169 16.005470,105.978378 16.005470,105.978378 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M136.466125,226.000000 
	C135.999908,221.309067 135.999802,216.618149 135.999557,211.463852 
	C135.999420,211.000488 135.994293,211.006622 136.460541,211.004303 
	C141.617661,211.001129 146.308548,211.000275 150.999710,210.999710 
	C151.000092,215.689255 151.000183,220.378510 151.000137,225.533875 
	C146.310745,226.000000 141.621490,226.000000 136.466125,226.000000 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M16.006483,90.993546 
	C11.315088,90.995056 6.623693,90.996567 1.466149,90.999039 
	C1.000000,86.310745 1.000000,81.621490 1.466144,76.465302 
	C6.623363,75.997116 11.314438,75.995872 16.003683,76.461365 
	C15.999058,81.620926 15.996262,86.313759 15.996445,91.003593 
	C15.999426,91.000587 16.006483,90.993553 16.006483,90.993546 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M196.000000,226.000000 
	C191.310745,226.000000 186.621490,226.000000 181.467682,225.533875 
	C181.004242,220.376404 181.005341,215.685074 181.006439,210.993729 
	C181.006439,210.993729 181.000000,211.000000 181.463669,211.002823 
	C186.618225,211.003754 191.309113,211.001877 195.997864,211.466125 
	C195.997147,216.621490 195.998581,221.310745 196.000000,226.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M226.000000,75.533882 
	C221.309418,75.999680 216.618835,75.999359 211.464294,75.999626 
	C211.000320,76.000214 211.004517,76.004517 211.002945,75.537933 
	C210.999893,70.379921 210.998413,65.688484 211.463257,60.998619 
	C216.619705,61.000126 221.309860,61.000061 226.000000,61.000000 
	C226.000000,65.689255 226.000000,70.378510 226.000000,75.533882 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M196.466125,226.000000 
	C195.998581,221.310745 195.997147,216.621490 196.463989,211.463623 
	C201.621490,210.996689 206.310745,210.998337 211.002655,211.466110 
	C211.003555,216.621490 211.001770,221.310745 211.000000,226.000000 
	C206.310745,226.000000 201.621490,226.000000 196.466125,226.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M211.472656,226.000000 
	C211.001770,221.310745 211.003555,216.621490 211.468781,211.467957 
	C216.621490,211.002457 221.310745,211.001236 226.000000,211.000000 
	C226.000000,215.924530 226.000000,220.849045 226.000000,226.000000 
	C221.316681,226.000000 216.630997,226.000000 211.472656,226.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M226.000000,121.000000 
	C226.000000,125.689255 226.000000,130.378510 225.533859,135.535400 
	C220.377243,136.003265 215.686783,136.003479 210.998657,135.537155 
	C211.001892,130.379227 211.002777,125.687843 211.003662,120.996460 
	C211.003662,120.996460 211.000000,121.000000 211.464706,120.999329 
	C216.619614,120.999107 221.309799,120.999550 226.000000,121.000000 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M1.000000,166.466110 
	C5.688060,165.998688 10.376121,165.997391 15.532196,165.998169 
	C16.000210,166.000259 16.004242,166.004272 16.005791,166.469971 
	C16.005684,171.624588 16.004026,176.313507 15.536104,181.001785 
	C10.379893,181.000763 5.689947,181.000381 1.000000,181.000000 
	C1.000000,176.310745 1.000000,171.621490 1.000000,166.466110 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M210.996307,136.003693 
	C215.686783,136.003479 220.377243,136.003265 225.533859,136.001526 
	C226.000000,140.689255 226.000000,145.378510 225.533844,150.532806 
	C220.376389,151.000717 215.685089,151.003571 210.993332,150.539612 
	C210.996506,145.380600 211.000137,140.688416 211.001892,135.998108 
	C211.000000,136.000000 210.996307,136.003693 210.996307,136.003693 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M226.000000,166.000000 
	C226.000000,170.689255 226.000000,175.378510 225.533875,180.533722 
	C220.377487,180.998764 215.687210,180.997864 210.998474,180.998474 
	C211.000000,181.000000 211.003052,181.003052 211.001373,180.536621 
	C210.998611,175.378922 210.997528,170.687668 211.462769,165.996094 
	C216.619400,165.997177 221.309708,165.998581 226.000000,166.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M1.000000,181.466125 
	C5.689947,181.000381 10.379893,181.000763 15.534891,181.000565 
	C15.999942,181.000000 15.997550,180.997559 16.000484,181.463928 
	C16.004004,186.621933 16.004589,191.313553 15.538746,196.001358 
	C10.381545,195.998352 5.690773,195.999176 1.000000,196.000000 
	C1.000000,191.310745 1.000000,186.621490 1.000000,181.466125 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M210.996948,180.996948 
	C215.687210,180.997864 220.377487,180.998764 225.533875,180.999832 
	C226.000000,185.689255 226.000000,190.378510 225.533844,195.533081 
	C220.376511,196.000885 215.685333,196.003372 210.996399,195.539093 
	C210.998093,190.380539 210.997528,185.688736 210.996948,180.996948 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M1.000000,196.466125 
	C5.690773,195.999176 10.381545,195.998352 15.535976,195.998566 
	C15.999635,195.999603 15.994787,195.994812 15.996912,196.461456 
	C15.997179,201.620941 15.995322,206.313782 15.996445,211.003601 
	C15.999425,211.000580 16.006483,210.993561 15.539959,210.994400 
	C10.382290,210.996826 5.691145,210.998413 1.000000,211.000000 
	C1.000000,206.310745 1.000000,201.621490 1.000000,196.466125 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M226.000000,165.533875 
	C221.309708,165.998581 216.619400,165.997177 211.464554,165.997894 
	C211.000000,166.000000 211.003662,166.003662 211.001343,165.537155 
	C211.001389,160.378433 211.003738,155.686234 211.003250,150.996796 
	C211.000412,150.999573 210.993774,151.006439 210.993774,151.006439 
	C215.685089,151.003571 220.376389,151.000717 225.533844,150.998932 
	C226.000000,155.689255 226.000000,160.378510 226.000000,165.533875 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M210.994141,196.005859 
	C215.685333,196.003372 220.376511,196.000885 225.533844,195.999191 
	C226.000000,200.689255 226.000000,205.378510 226.000000,210.533875 
	C221.310745,211.001236 216.621490,211.002457 211.463715,210.535721 
	C210.998749,205.376526 211.002319,200.685318 211.003143,195.996857 
	C211.000397,195.999603 210.994141,196.005875 210.994141,196.005859 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M1.000000,211.472656 
	C5.691145,210.998413 10.382290,210.996826 15.536259,211.461212 
	C15.999388,216.618118 15.999694,221.309052 16.000000,226.000000 
	C11.075477,226.000000 6.150953,226.000000 1.000000,226.000000 
	C1.000000,221.316681 1.000000,216.630997 1.000000,211.472656 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M16.466118,226.000000 
	C15.999694,221.309052 15.999388,216.618118 16.002783,211.460358 
	C16.006483,210.993561 15.999425,211.000580 16.463272,211.001343 
	C21.618053,211.001404 26.308989,211.000702 30.999962,211.000000 
	C31.000000,211.000000 30.999847,211.000000 30.999847,211.000000 
	C31.000017,215.689255 31.000187,220.378510 31.000179,225.533875 
	C26.310745,226.000000 21.621489,226.000000 16.466118,226.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M75.999695,211.000000 
	C75.999275,215.689255 75.998856,220.378510 75.999222,225.533875 
	C71.310745,226.000000 66.621490,226.000000 61.467690,225.533875 
	C61.004219,220.376373 61.005291,215.685013 61.006367,210.993637 
	C61.006367,210.993637 61.000164,211.000000 61.463867,211.002930 
	C66.618279,211.003906 71.308983,211.001953 75.999695,211.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M181.000000,1.000000 
	C185.689255,1.000000 190.378510,1.000000 195.533112,1.466148 
	C196.001083,6.623637 196.003708,11.314977 195.539536,16.007462 
	C190.380234,16.004156 185.687759,15.999710 180.997467,15.997492 
	C180.999649,15.999721 181.004608,16.004629 181.004028,15.538222 
	C181.002289,10.381210 181.001144,5.690605 181.000000,1.000000 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M226.000000,60.533882 
	C221.309860,61.000061 216.619705,61.000126 211.464783,61.000149 
	C211.000000,61.000111 211.003052,61.002956 211.001373,60.536533 
	C210.998611,55.378910 210.997513,50.687706 211.462769,45.996147 
	C216.619400,45.997192 221.309708,45.998596 226.000000,46.000000 
	C226.000000,50.689255 226.000000,55.378513 226.000000,60.533882 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M1.000000,46.466118 
	C5.688038,45.998688 10.376077,45.997372 15.532166,45.998138 
	C16.000217,46.000217 16.004240,46.004238 16.005791,46.469917 
	C16.005686,51.624527 16.004028,56.313450 15.536106,61.001747 
	C10.379893,61.000748 5.689947,61.000374 1.000000,61.000000 
	C1.000000,56.310745 1.000000,51.621487 1.000000,46.466118 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M226.000000,45.533882 
	C221.309708,45.998596 216.619400,45.997192 211.464554,45.997959 
	C211.000000,46.000130 211.003662,46.003559 211.001160,45.537086 
	C210.999115,40.380405 210.999557,35.690205 211.466110,31.002796 
	C216.621475,31.003729 221.310745,31.001865 226.000000,31.000000 
	C226.000000,35.689255 226.000000,40.378513 226.000000,45.533882 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M226.000000,30.533882 
	C221.310745,31.001865 216.621475,31.003729 211.463348,30.536682 
	C210.997574,25.377230 211.000671,20.686689 211.001892,15.997983 
	C211.000000,15.999815 210.996246,16.003832 211.462601,16.002079 
	C216.619308,16.000216 221.309647,16.000107 226.000000,16.000000 
	C226.000000,20.689255 226.000000,25.378511 226.000000,30.533882 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M226.000000,15.527336 
	C221.309647,16.000107 216.619308,16.000216 211.464905,15.535533 
	C211.000565,10.380494 211.000275,5.690247 211.000000,1.000000 
	C215.924515,1.000000 220.849045,1.000000 226.000000,1.000000 
	C226.000000,5.683316 226.000000,10.368995 226.000000,15.527336 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M210.533875,1.000000 
	C211.000275,5.690247 211.000565,10.380494 210.998535,15.537287 
	C210.996246,16.003832 211.000000,15.999815 210.535339,15.999582 
	C205.378311,15.997404 200.685928,15.995457 195.996490,15.996475 
	C195.999420,15.999442 196.006348,16.006317 196.006348,16.006317 
	C196.003708,11.314977 196.001083,6.623637 195.999237,1.466148 
	C200.689255,1.000000 205.378510,1.000000 210.533875,1.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M1.000000,61.466118 
	C5.689947,61.000374 10.379893,61.000748 15.534891,61.000561 
	C15.999941,61.000000 15.997550,60.997559 16.000484,61.463928 
	C16.000473,66.622063 15.997526,71.313820 15.997078,76.002975 
	C15.999578,76.000374 16.005512,75.994637 16.005512,75.994637 
	C11.314438,75.995872 6.623363,75.997116 1.466144,75.999176 
	C1.000000,71.310745 1.000000,66.621490 1.000000,61.466118 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M180.533875,1.000000 
	C181.001144,5.690605 181.002289,10.381210 180.537384,15.537755 
	C175.380493,16.002033 170.689636,16.000372 165.998779,15.998712 
	C165.998779,15.998712 166.000000,16.000000 165.998291,15.534328 
	C165.997726,10.379105 165.998856,5.689552 166.000000,1.000000 
	C170.689255,1.000000 175.378510,1.000000 180.533875,1.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M165.533875,1.000000 
	C165.998856,5.689552 165.997726,10.379105 165.998901,15.534901 
	C165.672394,15.999370 165.342606,16.013309 165.014862,15.993449 
	C160.493851,15.719465 154.501297,17.053999 151.896027,14.677012 
	C149.618332,12.598893 151.151337,6.344116 151.000427,1.467253 
	C155.689255,1.000000 160.378510,1.000000 165.533875,1.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M135.533875,1.000000 
	C135.997681,5.687575 135.995361,10.375150 135.529449,15.528902 
	C130.374954,15.998262 125.684029,16.001448 120.993958,15.538225 
	C120.996536,10.381213 120.998268,5.690607 121.000000,1.000000 
	C125.689255,1.000000 130.378510,1.000000 135.533875,1.000000 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M120.533882,1.000000 
	C120.998268,5.690607 120.996536,10.381213 120.997681,15.535761 
	C121.000542,15.999701 121.004517,15.993264 120.537895,15.995310 
	C115.378838,16.006622 110.686401,16.015890 105.993965,16.025158 
	C105.996132,11.327603 105.998306,6.630046 106.000244,1.466245 
	C110.689255,1.000000 115.378510,1.000000 120.533882,1.000000 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M90.533882,1.000000 
	C91.000252,5.689918 91.000511,10.379836 91.000397,15.534872 
	C91.000038,15.999991 91.002266,15.996880 90.535919,15.998257 
	C85.379204,16.000250 80.688835,16.000868 75.998581,15.535275 
	C75.999130,10.379376 75.999565,5.689688 76.000000,1.000000 
	C80.689255,1.000000 85.378510,1.000000 90.533882,1.000000 
z"/>
<path fill="#EDEEEE" opacity="1.000000" stroke="none" 
	d="
M75.533882,1.000000 
	C75.999565,5.689688 75.999130,10.379376 75.999344,15.534494 
	C76.000000,15.999924 76.002609,15.997395 75.536667,16.000500 
	C70.378746,16.003340 65.686752,16.003075 60.997318,15.536521 
	C60.999912,10.380157 60.999954,5.690078 61.000000,1.000000 
	C65.689255,1.000000 70.378510,1.000000 75.533882,1.000000 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M60.533882,1.000000 
	C60.999954,5.690078 60.999912,10.380157 61.000130,15.535045 
	C61.000393,15.999855 61.002987,15.994447 60.536572,15.997931 
	C55.379547,16.000399 50.688934,15.999385 45.999161,15.999176 
	C46.000000,15.999982 46.001678,16.001684 46.001678,16.001684 
	C46.000793,11.311872 45.999908,6.622062 45.999512,1.466125 
	C50.689255,1.000000 55.378513,1.000000 60.533882,1.000000 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M16.375124,16.840014 
	C15.996252,11.983414 15.998634,6.958574 16.000507,1.466867 
	C20.689255,1.000000 25.378511,1.000000 30.533884,1.000000 
	C30.777407,5.753828 30.554811,10.507655 30.168383,15.648108 
	C25.588491,16.247078 21.172434,16.459425 16.375124,16.840014 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M226.000000,120.533882 
	C221.309799,120.999550 216.619614,120.999107 211.462875,121.001144 
	C210.999756,117.368744 211.078445,113.731873 210.983032,110.099579 
	C210.905136,107.133377 212.232498,105.895737 215.157242,105.981781 
	C218.458801,106.078911 221.765472,106.001846 225.534973,106.000275 
	C226.000000,110.689255 226.000000,115.378510 226.000000,120.533882 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M60.540703,210.994476 
	C61.005291,215.685013 61.004219,220.376373 61.001572,225.533875 
	C56.310745,226.000000 51.621487,226.000000 46.466118,226.000000 
	C46.001633,222.365082 46.075912,218.728287 45.982166,215.095825 
	C45.905632,212.130035 47.237076,210.896591 50.162296,210.983688 
	C53.463734,211.081970 56.770554,210.999298 60.540703,210.994476 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M120.537712,210.999512 
	C121.002197,215.687210 121.001343,220.377487 121.000244,225.533875 
	C116.310745,226.000000 111.621490,226.000000 106.466118,226.000000 
	C106.001717,222.365448 106.078888,218.728867 105.981583,215.096954 
	C105.901978,212.125259 107.243706,210.899063 110.160583,210.982590 
	C113.461998,211.077133 116.768234,211.002899 120.537712,210.999512 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M180.540771,210.994598 
	C181.005341,215.685074 181.004242,220.376404 181.001572,225.533875 
	C176.310745,226.000000 171.621490,226.000000 166.466125,226.000000 
	C166.001663,222.365097 166.075943,218.728317 165.982269,215.095871 
	C165.905792,212.130173 167.237137,210.896652 170.162399,210.983795 
	C173.463806,211.082138 176.770630,210.999420 180.540771,210.994598 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M1.000000,136.466110 
	C5.747162,136.220261 10.494323,136.440521 15.623623,136.828125 
	C16.001974,140.298721 15.921071,143.604233 16.017286,146.904587 
	C16.103912,149.876038 14.754851,151.100555 11.839250,151.017044 
	C8.538711,150.922516 5.233333,150.996902 1.465029,150.998932 
	C1.000000,146.310745 1.000000,141.621490 1.000000,136.466110 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M1.000000,16.466118 
	C5.747178,16.220282 10.494355,16.440561 15.623680,16.828175 
	C16.002041,20.298786 15.921126,23.604317 16.017359,26.904680 
	C16.104002,29.876181 14.754831,31.100597 11.839283,31.017103 
	C8.538732,30.922588 5.233344,30.996979 1.465029,30.998962 
	C1.000000,26.310745 1.000000,21.621489 1.000000,16.466118 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M195.993958,105.026489 
	C195.993958,105.026489 195.999969,105.493187 195.536789,105.739960 
	C190.380493,105.993057 185.687363,105.999390 180.996490,105.538971 
	C180.999161,100.381584 180.999588,95.690941 181.466125,91.002182 
	C186.623657,91.004837 191.315048,91.005608 196.003510,91.003471 
	C196.000580,91.000557 195.993744,90.993706 195.993576,91.458801 
	C195.993591,96.291435 195.993774,100.658958 195.993958,105.026489 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M210.537140,120.998528 
	C211.002777,125.687843 211.001892,130.379227 210.998657,135.537155 
	C210.996307,136.003693 211.000000,136.000000 210.535339,135.999664 
	C205.379837,136.000168 200.689011,136.000992 195.998444,135.535507 
	C196.000870,130.377716 196.003036,125.686256 196.002777,120.997223 
	C196.000366,120.999641 195.994827,121.005219 195.994827,121.005219 
	C200.686752,121.003677 205.378693,121.002136 210.537140,120.998528 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M180.994247,106.005722 
	C185.687363,105.999390 190.380493,105.993057 195.540466,105.973297 
	C196.004349,110.663826 196.001358,115.367767 195.996597,120.538467 
	C195.994827,121.005219 196.000366,120.999641 195.536057,120.998550 
	C190.379089,121.000404 185.686417,121.003357 180.994476,120.539490 
	C180.998718,115.379860 181.002243,110.687057 181.003067,105.996887 
	C181.000366,105.999519 180.994247,106.005714 180.994247,106.005722 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M196.377472,105.187134 
	C195.993774,100.658958 195.993591,96.291435 196.460373,91.458633 
	C201.618225,90.995476 206.309113,90.997581 211.000000,90.999695 
	C211.000000,90.999695 211.000000,91.000000 211.000000,91.000153 
	C210.995209,94.771088 211.246872,98.569382 210.846954,102.297775 
	C210.706390,103.608002 209.034088,105.713249 207.946548,105.788025 
	C204.245605,106.042488 200.493546,105.553123 196.377472,105.187134 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M61.005627,195.993637 
	C60.173557,195.997040 59.339691,195.966156 58.509731,196.009735 
	C54.724270,196.208572 49.947853,195.141357 47.471119,197.050491 
	C45.512882,198.559952 46.229649,203.726868 46.040440,207.284546 
	C45.890751,210.099167 44.534351,211.069809 41.914524,211.013885 
	C38.587765,210.942886 35.258274,211.000153 31.464903,211.001053 
	C30.999847,211.000000 31.000000,211.000000 30.998695,210.533875 
	C31.000353,205.376373 31.003317,200.685013 31.003149,195.996826 
	C31.000017,196.000000 30.993734,196.006226 31.459377,196.003052 
	C35.228245,195.997345 38.533520,195.921646 41.834076,196.014221 
	C44.752892,196.096100 46.090611,194.853455 46.016563,191.894012 
	C45.925713,188.262726 45.994267,184.627441 46.460434,180.996201 
	C51.620045,180.996872 56.313129,180.995239 61.004921,181.460419 
	C61.000271,186.620056 60.996918,191.312851 60.996559,196.003113 
	C60.999557,196.000549 61.005627,195.993637 61.005627,195.993637 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M75.999847,211.000000 
	C71.308983,211.001953 66.618279,211.003906 61.460800,211.005920 
	C60.996521,206.313080 60.999012,201.620178 61.003563,196.460464 
	C61.005627,195.993637 60.999557,196.000549 61.412449,196.003464 
	C64.185219,195.008102 66.545090,194.009827 68.987869,193.409424 
	C69.666283,194.826828 70.261803,195.846344 70.792099,197.199127 
	C72.482994,198.367188 74.239128,199.201981 76.000061,200.492218 
	C76.003334,204.298431 76.001816,207.649216 76.000290,211.000000 
	C76.000290,211.000000 76.000000,211.000000 75.999847,211.000000 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M210.998474,180.998474 
	C210.997528,185.688736 210.998093,190.380539 210.996399,195.539093 
	C210.994141,196.005875 211.000397,195.999603 210.537476,196.001007 
	C206.771698,196.004898 203.466766,196.080551 200.166565,195.987930 
	C197.252609,195.906113 195.906799,197.133316 195.983154,200.101135 
	C196.076584,203.731827 196.001953,207.366852 196.000000,211.000000 
	C191.309113,211.001877 186.618225,211.003754 181.460541,211.006042 
	C180.996216,206.313538 180.998672,201.620636 181.000351,196.464050 
	C180.999588,196.000351 180.994827,196.005829 181.460419,196.005066 
	C185.229370,196.000900 188.534760,195.924133 191.835449,196.016098 
	C194.753174,196.097427 196.091095,194.861420 196.016037,191.897186 
	C195.924103,188.265945 196.000214,184.630417 196.001831,180.998383 
	C196.000000,181.000000 195.996841,181.003662 196.463165,181.001526 
	C201.620667,181.000595 206.311859,181.001831 211.003052,181.003052 
	C211.003052,181.003052 211.000000,181.000000 210.998474,180.998474 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M181.002716,195.997589 
	C180.173767,195.998352 179.339935,195.967728 178.509964,196.011246 
	C174.724548,196.209793 169.948273,195.142075 167.471451,197.050949 
	C165.513290,198.560150 166.230072,203.727219 166.040741,207.284851 
	C165.890961,210.099426 164.534073,211.069977 161.914551,211.014221 
	C158.587830,210.943405 155.258362,211.000473 151.463470,210.543823 
	C150.999985,206.739731 151.003143,203.394180 151.373917,199.977753 
	C153.250748,199.021103 154.759964,198.135345 156.247101,197.201630 
	C156.225052,197.153702 156.176407,197.247345 156.515533,197.128204 
	C157.261017,195.675262 157.667358,194.341461 158.425735,193.072464 
	C160.280823,194.006531 161.783859,194.875778 163.286911,195.745041 
	C164.190842,193.967255 165.617432,192.263107 165.880646,190.395035 
	C166.314301,187.317215 165.995392,184.133377 166.460220,180.996216 
	C171.617615,180.998596 176.308502,180.998993 181.000824,181.465820 
	C180.999786,186.623444 180.997314,191.314636 180.994843,196.005829 
	C180.994827,196.005829 180.999588,196.000351 181.002716,195.997589 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M15.998022,45.998028 
	C15.999283,42.362476 16.078712,38.727100 15.982459,35.096382 
	C15.903681,32.124882 17.245258,30.901985 20.161196,30.984671 
	C23.461502,31.078255 26.766685,30.999792 30.534874,30.998070 
	C31.000000,31.000000 31.000229,31.000229 31.002571,31.466324 
	C31.004814,36.623154 31.004711,41.313885 30.538212,46.002823 
	C25.382624,46.002094 20.693432,46.003166 16.004240,46.004238 
	C16.004240,46.004238 16.000217,46.000217 15.998022,45.998028 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M30.991577,17.462082 
	C35.681244,16.663689 40.377720,16.330185 45.537937,15.999182 
	C46.001678,16.001684 46.000000,15.999982 46.000515,16.465509 
	C46.002766,21.622805 46.004501,26.314575 45.539444,31.006512 
	C40.381844,31.004532 35.691036,31.002380 31.000229,31.000229 
	C31.000229,31.000229 31.000000,31.000000 30.999886,30.999886 
	C30.999310,26.642172 30.998848,22.284571 30.991577,17.462082 
z"/>
<path fill="#3B3B3B" opacity="1.000000" stroke="none" 
	d="
M70.857330,196.865860 
	C70.261803,195.846344 69.666283,194.826828 69.067581,192.969086 
	C69.130058,189.748764 69.195717,187.366653 69.580109,184.908447 
	C69.676521,183.559250 69.454201,182.286163 69.147102,180.614960 
	C69.132141,179.139999 69.201965,178.063141 69.642395,176.870453 
	C70.300850,173.420059 70.588707,170.085526 70.890564,166.618637 
	C70.904556,166.486298 70.877579,166.221527 71.344940,166.155975 
	C74.943092,166.058777 78.170113,166.488419 81.176666,165.861206 
	C83.987411,165.274841 88.245827,166.634903 88.935822,161.322113 
	C88.910156,157.592056 88.870377,154.305069 88.902794,150.555618 
	C88.927200,145.389496 88.879410,140.685852 88.903442,135.522110 
	C88.928482,130.380219 88.881691,125.698395 88.907707,120.558105 
	C89.043251,116.063950 89.105995,112.028259 89.524460,107.908127 
	C90.266609,106.815186 90.664803,105.811020 91.037300,104.797417 
	C92.267647,101.449493 92.549194,97.374870 96.581108,95.958916 
	C98.934082,95.132584 101.528740,94.994446 104.087143,94.533630 
	C100.727165,89.932762 105.148376,87.163956 107.285042,84.468597 
	C108.996498,82.309631 112.499725,81.534470 115.260124,80.258438 
	C116.722885,79.582253 118.297203,79.147385 119.821159,78.603577 
	C118.962578,77.675545 117.994904,76.823524 117.278984,75.796082 
	C116.676971,74.932106 116.367981,73.863945 115.946915,72.931511 
	C115.964561,72.976837 115.880882,72.927231 116.294502,72.946640 
	C119.321861,71.949875 122.003349,71.072784 124.516006,69.849068 
	C125.521172,69.359528 126.918762,67.969574 126.786789,67.230827 
	C126.559868,65.960609 125.273743,64.879601 124.614456,63.426704 
	C129.235458,58.504066 133.674210,53.873894 138.415497,49.122238 
	C139.287567,48.356155 139.857117,47.711555 140.696121,46.955429 
	C142.022369,45.680012 143.079147,44.516117 144.415192,43.231194 
	C144.933411,42.786404 145.172379,42.462643 145.655731,41.991791 
	C146.645828,41.008316 147.391525,40.171928 148.423737,39.231186 
	C148.938385,38.801147 149.166534,38.475460 149.580093,38.089760 
	C149.999390,37.874939 150.129471,37.657913 150.488663,37.235733 
	C152.586502,35.080772 154.351425,33.068741 156.356720,30.972834 
	C156.733841,30.788422 156.870575,30.687885 157.007294,30.587351 
	C157.155533,30.669096 157.303757,30.750839 157.680389,31.361984 
	C157.841965,33.601063 157.775162,35.310741 157.369751,37.112209 
	C156.686600,40.325661 156.122253,43.441944 156.045746,46.570160 
	C155.909653,52.135471 156.068924,57.708004 156.002228,63.634281 
	C154.256454,64.432770 152.613068,64.874489 150.517548,65.232903 
	C146.377838,65.181885 142.690262,65.214180 138.745544,65.186630 
	C138.377731,65.418983 138.267059,65.711197 138.076447,66.455009 
	C138.060608,70.264870 138.124710,73.623123 138.111328,77.440353 
	C138.085892,81.939667 138.137924,85.980011 138.106339,90.480057 
	C138.079315,96.286324 138.135895,101.632889 138.116272,107.440201 
	C138.080048,112.273018 138.120041,116.645103 138.087082,121.477119 
	C138.068207,126.619179 138.122269,131.301300 138.106873,136.446136 
	C138.076889,141.610962 138.116379,146.313049 138.077759,151.462799 
	C137.998459,153.717194 137.736221,155.574249 138.071808,157.316116 
	C138.387817,158.956314 139.334000,160.475082 139.952209,162.367950 
	C139.437485,163.564011 138.973511,164.439377 138.671097,165.009918 
	C143.578247,165.009918 148.284271,165.009918 153.002075,165.345947 
	C156.440201,168.030563 156.063049,171.537598 156.036591,175.052933 
	C155.980881,182.450302 156.118271,189.849121 156.176407,197.247345 
	C156.176407,197.247345 156.225052,197.153702 155.809509,197.152222 
	C152.937546,197.443161 150.481796,197.982971 148.024582,197.989609 
	C124.217758,198.053833 100.410599,198.055283 76.603844,197.978622 
	C74.687164,197.972443 72.772781,197.253555 70.857330,196.865860 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M166.000168,16.464989 
	C170.689636,16.000372 175.380493,16.002033 180.537979,16.004162 
	C181.004608,16.004629 180.999649,15.999721 180.999115,16.464170 
	C180.996948,21.621164 180.995331,26.313707 180.997131,31.002865 
	C181.000549,30.999479 181.006256,30.993731 180.539459,30.993992 
	C175.379761,30.994009 170.686874,30.993767 165.996674,30.996490 
	C165.999374,30.999456 166.006058,31.005589 166.006042,31.005589 
	C166.004547,26.314148 166.003052,21.622705 166.000168,16.464989 
z"/>
<path fill="#FDFEFE" opacity="1.000000" stroke="none" 
	d="
M120.996826,16.002167 
	C125.684029,16.001448 130.374954,15.998262 135.531708,15.994654 
	C135.997559,15.994229 135.998795,16.493523 135.999146,17.174688 
	C136.341629,20.241032 136.683746,22.626209 137.016434,25.335150 
	C136.999969,26.091000 136.992935,26.523085 136.527405,27.045490 
	C131.050735,27.076128 126.032570,27.016445 121.012115,26.634510 
	C120.995651,25.883133 120.981476,25.454012 120.977509,24.573322 
	C120.993324,21.412258 120.998924,18.702761 121.004517,15.993264 
	C121.004517,15.993264 121.000542,15.999701 120.996826,16.002167 
z"/>
<path fill="#ECEEEF" opacity="1.000000" stroke="none" 
	d="
M137.025864,25.011387 
	C136.683746,22.626209 136.341629,20.241032 135.998840,17.424335 
	C139.888580,16.696716 143.790970,16.063580 147.665970,16.201284 
	C151.385956,16.333481 151.094330,19.469397 150.992767,22.866783 
	C150.661530,24.063089 150.350342,24.537443 149.645111,25.012951 
	C145.175980,25.013199 141.100922,25.012293 137.025864,25.011387 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M157.708359,37.020420 
	C157.775162,35.310741 157.841965,33.601063 157.913422,31.462799 
	C160.315079,31.024942 162.712051,31.015671 165.557556,31.005995 
	C166.006058,31.005589 165.999374,30.999456 165.996124,31.462158 
	C165.985352,35.585606 166.223099,39.273380 165.824417,42.891014 
	C165.700974,44.011166 163.985672,45.317265 162.721344,45.781399 
	C162.177292,45.981117 160.883011,44.295826 160.009583,43.389782 
	C159.302170,42.655949 158.714478,41.806698 157.976715,40.618103 
	C157.822632,39.158516 157.765503,38.089466 157.708359,37.020420 
z"/>
<path fill="#F7E6E3" opacity="1.000000" stroke="none" 
	d="
M156.116364,31.056709 
	C154.351425,33.068741 152.586502,35.080772 150.547852,36.875626 
	C149.830383,35.430923 149.386658,34.203396 148.986725,32.677376 
	C149.088516,31.980854 149.146500,31.582825 149.549866,31.155430 
	C150.896973,29.779531 151.898727,28.432997 153.244934,27.041332 
	C154.394806,26.676743 155.200226,26.357281 156.005661,26.037819 
	C156.317719,26.488846 156.629776,26.939871 156.668915,27.813938 
	C155.750824,28.896566 155.105652,29.556149 154.460495,30.215729 
	C155.012451,30.496056 155.564407,30.776382 156.116364,31.056709 
z"/>
<path fill="#FBFCFB" opacity="1.000000" stroke="none" 
	d="
M155.993042,25.642796 
	C155.200226,26.357281 154.394806,26.676743 152.878296,27.071266 
	C151.441315,27.082916 150.715393,27.019503 149.999573,26.632015 
	C150.019485,25.875893 150.029327,25.443844 150.039169,25.011795 
	C150.350342,24.537443 150.661530,24.063089 150.998657,23.291279 
	C152.676544,23.745136 154.328476,24.496454 155.993042,25.642796 
z"/>
<path fill="#FBFCFB" opacity="1.000000" stroke="none" 
	d="
M156.356720,30.972834 
	C155.564407,30.776382 155.012451,30.496056 154.460495,30.215729 
	C155.105652,29.556149 155.750824,28.896566 156.688263,28.103582 
	C156.989975,28.624687 156.999405,29.279194 157.008057,30.260525 
	C156.870575,30.687885 156.733841,30.788422 156.356720,30.972834 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M16.370127,136.842224 
	C15.996758,131.981903 15.999669,126.955589 16.004166,121.461777 
	C16.005753,120.994270 15.999555,121.000465 16.463669,121.001083 
	C21.619780,120.999886 26.311777,120.998055 31.001682,121.450760 
	C31.006769,124.937309 31.013947,127.969322 31.013115,131.393494 
	C31.225397,134.667923 30.044567,136.224686 27.000505,136.018814 
	C23.582474,136.237946 20.164440,136.457077 16.370127,136.842224 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M15.998056,165.997986 
	C15.997868,162.859436 16.223682,159.703308 15.947962,156.591629 
	C15.559757,152.210464 17.330692,150.493790 21.639143,150.969467 
	C23.432562,151.167496 25.266920,150.994705 27.917248,150.990967 
	C29.503214,150.992035 30.254597,150.992676 31.006218,151.460144 
	C31.005842,156.619522 31.005226,161.312057 30.538212,166.002777 
	C25.382624,166.002075 20.693434,166.003174 16.004242,166.004272 
	C16.004242,166.004272 16.000210,166.000259 15.998056,165.997986 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M31.005980,150.993301 
	C30.254597,150.992676 29.503214,150.992035 28.376213,150.984360 
	C27.667097,146.291397 27.333603,141.605484 27.000305,136.469177 
	C30.044567,136.224686 31.225397,134.667923 31.405657,131.441528 
	C36.533039,131.398254 41.259872,131.699127 45.991821,132.400024 
	C45.998413,133.867111 45.999893,134.934158 45.536186,135.998596 
	C41.766308,135.999191 38.459412,136.078461 35.157578,135.982864 
	C32.233475,135.898209 30.905558,137.133347 30.983702,140.101212 
	C31.079346,143.733734 30.998013,147.370926 30.996346,151.003342 
	C30.999491,151.000595 31.005980,150.993301 31.005980,150.993301 
z"/>
<path fill="#494949" opacity="1.000000" stroke="none" 
	d="
M70.792099,197.199127 
	C72.772781,197.253555 74.687164,197.972443 76.603844,197.978622 
	C100.410599,198.055283 124.217758,198.053833 148.024582,197.989609 
	C150.481796,197.982971 152.937546,197.443161 155.831573,197.200165 
	C154.759964,198.135345 153.250748,199.021103 150.915756,199.884186 
	C145.391708,199.919830 140.693466,199.978165 135.534210,199.964050 
	C130.384186,199.942795 125.695145,199.994019 121.006111,200.045227 
	C116.307487,199.986343 111.608864,199.927460 105.991028,199.886353 
	C100.382812,199.948441 95.693794,199.992722 90.546913,199.942322 
	C85.391113,199.910675 80.693192,199.973724 75.995262,200.036774 
	C74.239128,199.201981 72.482994,198.367188 70.792099,197.199127 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M121.000633,200.500275 
	C125.695145,199.994019 130.384186,199.942795 135.539215,200.419525 
	C136.001572,204.300522 135.997925,207.653580 135.994293,211.006622 
	C135.994293,211.006622 135.999420,211.000488 136.003052,210.997391 
	C131.314377,210.995514 126.622055,210.996735 121.463257,211.000458 
	C120.996231,207.653763 120.995697,204.304535 121.000633,200.500275 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M91.004776,200.037003 
	C95.693794,199.992722 100.382812,199.948441 105.532318,199.979111 
	C105.898293,212.632614 107.465805,210.845657 94.893173,211.001266 
	C93.905830,211.013474 92.918236,211.004150 91.465836,211.003021 
	C91.000916,211.001221 91.000000,211.000000 90.997444,210.542419 
	C90.998184,206.735565 91.001480,203.386276 91.004776,200.037003 
z"/>
<path fill="#FDFEFE" opacity="1.000000" stroke="none" 
	d="
M90.998489,16.001127 
	C95.743477,16.220079 100.490005,16.437893 105.619446,17.262962 
	C106.008972,20.252237 106.015602,22.634254 105.774246,25.176325 
	C105.347672,25.884396 105.169083,26.432413 104.556740,27.065397 
	C101.774078,27.202477 99.425171,27.254587 96.786377,27.206335 
	C96.305313,27.178659 96.114151,27.251345 95.493301,27.276300 
	C93.047668,27.132174 91.031715,27.035778 89.015198,26.617817 
	C89.004501,25.867771 88.994385,25.439291 89.374184,24.926071 
	C90.176826,21.893181 90.589546,18.945030 91.002258,15.996880 
	C91.002266,15.996880 91.000038,15.999991 90.998489,16.001127 
z"/>
<path fill="#ECEEEF" opacity="1.000000" stroke="none" 
	d="
M106.022232,25.016272 
	C106.015602,22.634254 106.008972,20.252237 106.003815,17.422352 
	C106.005287,16.974482 105.999779,16.499510 105.996872,16.262333 
	C110.686401,16.015890 115.378838,16.006622 120.537903,15.995310 
	C120.998924,18.702761 120.993324,21.412258 120.515137,24.568184 
	C115.369118,25.015165 110.695671,25.015718 106.022232,25.016272 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M210.997589,90.533607 
	C206.309113,90.997581 201.618225,90.995476 196.460541,90.993538 
	C195.993744,90.993706 196.000580,91.000557 196.003998,90.537758 
	C196.006760,86.771507 196.078064,83.466011 195.983887,80.165230 
	C195.900620,77.246582 197.139374,75.910728 200.103729,75.987434 
	C203.735031,76.081398 207.370728,76.006439 211.004517,76.004517 
	C211.004517,76.004517 211.000320,76.000214 210.997894,75.997818 
	C210.995361,80.686119 210.995270,85.376823 210.997589,90.533607 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M31.003775,120.996223 
	C26.311777,120.998055 21.619780,120.999886 16.461006,121.003769 
	C15.997048,116.309067 15.999866,111.612312 16.004076,106.446968 
	C16.005470,105.978378 15.999511,105.498169 16.381527,105.418182 
	C21.510286,105.557541 26.257027,105.776886 31.001341,106.462769 
	C30.998014,111.620804 30.997116,116.312286 30.998030,121.001984 
	C30.999842,121.000191 31.003775,120.996223 31.003775,120.996223 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M90.546906,199.942322 
	C91.001480,203.386276 90.998184,206.735565 90.997154,210.542130 
	C86.310127,210.998047 81.620834,210.996689 76.465912,210.997650 
	C76.001816,207.649216 76.003334,204.298431 76.000061,200.492203 
	C80.693192,199.973724 85.391113,199.910675 90.546906,199.942322 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M31.003769,105.996231 
	C26.257027,105.776886 21.510286,105.557541 16.379381,105.178070 
	C15.996509,100.653404 15.997799,96.288857 16.002787,91.458939 
	C16.006483,90.993553 15.999426,91.000587 16.463303,91.001343 
	C21.620159,90.999214 26.313137,90.996330 31.006632,91.460281 
	C31.003483,96.619354 30.999815,101.311592 30.997993,106.002014 
	C30.999840,106.000198 31.003769,105.996231 31.003769,105.996231 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M136.460541,211.004303 
	C135.997925,207.653580 136.001572,204.300522 136.000214,200.491989 
	C140.693466,199.978165 145.391708,199.919830 150.548126,199.955078 
	C151.003143,203.394180 150.999985,206.739731 150.998138,210.542343 
	C146.308548,211.000275 141.617661,211.001129 136.460541,211.004303 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M31.006115,90.993446 
	C26.313137,90.996330 21.620159,90.999214 16.460323,91.004349 
	C15.996262,86.313759 15.999058,81.620926 16.003683,76.461365 
	C16.005512,75.994637 15.999578,76.000374 16.463829,76.000961 
	C21.620781,75.998940 26.313482,75.996330 31.005047,76.460541 
	C31.000397,81.620316 30.996885,86.313271 30.996422,91.003403 
	C30.999472,91.000580 31.006115,90.993446 31.006115,90.993446 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M211.002945,75.537933 
	C207.370728,76.006439 203.735031,76.081398 200.103729,75.987434 
	C197.139374,75.910728 195.900620,77.246582 195.983887,80.165230 
	C196.078064,83.466011 196.006760,86.771507 196.006943,90.540672 
	C191.315048,91.005608 186.623657,91.004837 181.466125,91.002029 
	C181.000000,91.000000 181.000000,90.999695 180.997116,90.533600 
	C180.994583,85.376762 180.994949,80.686020 181.460846,75.998360 
	C185.229706,75.998940 188.535065,75.923378 191.835739,76.015869 
	C194.753693,76.097633 196.090973,74.860718 196.015900,71.896729 
	C195.923935,68.265503 196.000153,64.630013 196.001831,60.998104 
	C196.000000,60.999844 195.996338,61.003735 196.462692,61.001556 
	C201.620377,61.000572 206.311722,61.001766 211.003052,61.002956 
	C211.003052,61.002956 211.000000,61.000111 210.998474,60.998581 
	C210.998413,65.688484 210.999893,70.379921 211.002945,75.537933 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M196.466125,210.997513 
	C196.001953,207.366852 196.076584,203.731827 195.983154,200.101135 
	C195.906799,197.133316 197.252609,195.906113 200.166565,195.987930 
	C203.466766,196.080551 206.771698,196.004898 210.540222,195.998260 
	C211.002319,200.685318 210.998749,205.376526 210.997589,210.533875 
	C206.310745,210.998337 201.621490,210.996689 196.466125,210.997513 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M16.005791,166.469971 
	C20.693434,166.003174 25.382624,166.002075 30.535784,166.000336 
	C30.999752,165.999680 30.995392,165.995422 30.996754,166.462006 
	C30.996685,171.621109 30.995253,176.313629 30.996681,181.003311 
	C30.999540,181.000488 31.006273,180.993683 30.539761,180.995041 
	C25.381348,180.996780 20.689449,180.997162 15.997550,180.997559 
	C15.997550,180.997559 15.999942,181.000000 16.001156,181.001221 
	C16.004026,176.313507 16.005684,171.624588 16.005791,166.469971 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M195.998169,136.001831 
	C200.689011,136.000992 205.379837,136.000168 210.537231,135.997787 
	C211.000137,140.688416 210.996506,145.380600 210.993332,150.539612 
	C210.993774,151.006439 211.000412,150.999573 210.537567,150.997742 
	C206.746017,150.998322 203.416153,151.056061 200.089005,150.985535 
	C197.471268,150.930023 196.109039,151.894867 195.959229,154.712006 
	C195.770020,158.270248 196.487808,163.437729 194.529480,164.947754 
	C192.053101,166.857224 187.275940,165.790390 183.490158,165.988190 
	C182.660263,166.031555 181.826157,165.994202 180.997253,165.997253 
	C181.000519,166.000504 181.006073,166.006180 181.007477,165.539398 
	C181.006134,160.381943 181.003372,155.691269 181.465576,151.001862 
	C185.234589,150.999664 188.541000,150.917648 191.841995,151.016251 
	C194.769760,151.103714 196.093964,149.863327 196.017822,146.900909 
	C195.924469,143.268921 195.999557,139.632614 196.000916,135.999084 
	C196.000000,136.000000 195.998169,136.001831 195.998169,136.001831 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M211.001373,180.536621 
	C206.311859,181.001831 201.620667,181.000595 196.465942,180.538116 
	C196.002518,177.105911 196.030884,174.134628 195.995377,171.164093 
	C195.953888,167.692810 197.597839,165.966766 201.100525,165.999451 
	C204.401306,166.030258 207.702591,166.004532 211.003662,166.003662 
	C211.003662,166.003662 211.000000,166.000000 210.998215,165.998199 
	C210.997528,170.687668 210.998611,175.378922 211.001373,180.536621 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M16.000484,181.463928 
	C20.689449,180.997162 25.381348,180.996780 30.538002,181.461853 
	C30.999748,186.620285 30.996742,191.313263 30.993734,196.006226 
	C30.993734,196.006226 31.000017,196.000000 30.536343,196.000732 
	C25.380043,195.999237 20.687414,195.997025 15.994787,195.994812 
	C15.994787,195.994812 15.999635,195.999603 16.002405,196.002380 
	C16.004589,191.313553 16.004004,186.621933 16.000484,181.463928 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M15.996912,196.461456 
	C20.687414,195.997025 25.380043,195.999237 30.539474,195.997543 
	C31.003317,200.685013 31.000353,205.376373 30.998657,210.533875 
	C26.308989,211.000702 21.618053,211.001404 16.460291,211.004364 
	C15.995322,206.313782 15.997179,201.620941 15.996912,196.461456 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M211.001343,165.537155 
	C207.702591,166.004532 204.401306,166.030258 201.100525,165.999451 
	C197.597839,165.966766 195.953888,167.692810 195.995377,171.164093 
	C196.030884,174.134628 196.002518,177.105911 195.999619,180.540268 
	C195.996841,181.003662 196.000000,181.000000 195.535294,180.999344 
	C190.380386,180.999115 185.690201,180.999557 180.998749,180.533646 
	C180.996323,175.376205 180.995148,170.685104 180.993988,165.994019 
	C181.826157,165.994202 182.660263,166.031555 183.490158,165.988190 
	C187.275940,165.790390 192.053101,166.857224 194.529480,164.947754 
	C196.487808,163.437729 195.770020,158.270248 195.959229,154.712006 
	C196.109039,151.894867 197.471268,150.930023 200.089005,150.985535 
	C203.416153,151.056061 206.746017,150.998322 210.540405,150.994965 
	C211.003738,155.686234 211.001389,160.378433 211.001343,165.537155 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M180.993729,31.006250 
	C180.995331,26.313707 180.996948,21.621164 180.996918,16.461943 
	C185.687759,15.999710 190.380234,16.004156 195.539536,16.007462 
	C196.006348,16.006317 195.999420,15.999442 195.997253,16.462219 
	C195.995255,20.230217 195.923569,23.537476 196.017120,26.840052 
	C196.099731,29.755919 194.876953,31.098328 191.900330,31.017109 
	C188.267395,30.917986 184.629486,31.001642 180.993729,31.006250 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M211.001373,60.536533 
	C206.311722,61.001766 201.620377,61.000572 196.465546,60.536125 
	C196.002914,56.770199 196.073257,53.465652 195.983826,50.165428 
	C195.904907,47.252968 197.140701,45.909058 200.105438,45.985516 
	C203.735916,46.079140 207.370728,46.005104 211.003662,46.003559 
	C211.003662,46.003559 211.000000,46.000130 210.998215,45.998318 
	C210.997513,50.687706 210.998611,55.378910 211.001373,60.536533 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M16.005791,46.469917 
	C20.693432,46.003166 25.382624,46.002094 30.535782,46.000374 
	C30.999748,45.999725 30.995440,45.995449 30.996990,46.462029 
	C30.999985,51.620037 31.001427,56.311459 30.536572,61.001312 
	C25.379364,60.999016 20.688457,60.998287 15.997550,60.997559 
	C15.997550,60.997559 15.999941,61.000000 16.001156,61.001190 
	C16.004028,56.313450 16.005686,51.624527 16.005791,46.469917 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M211.001160,45.537086 
	C207.370728,46.005104 203.735916,46.079140 200.105438,45.985516 
	C197.140701,45.909058 195.904907,47.252968 195.983826,50.165428 
	C196.073257,53.465652 196.002914,56.770199 195.999176,60.538300 
	C195.996338,61.003735 196.000000,60.999844 195.535263,60.999115 
	C190.378281,61.000999 185.686020,61.003605 180.994995,60.539421 
	C180.997665,55.381592 180.999130,50.690548 181.000305,45.999756 
	C181.000000,46.000000 180.999390,46.000610 181.464447,45.996872 
	C185.257385,45.992878 188.586334,45.940651 191.912827,46.008957 
	C194.529770,46.062695 195.886902,45.087440 196.036362,42.274250 
	C196.225342,38.717426 195.509933,33.550724 197.467728,32.042934 
	C199.944748,30.135269 204.719635,31.203856 208.504410,31.007521 
	C209.334167,30.964479 210.168030,31.000427 211.000000,31.000000 
	C210.999557,35.690205 210.999115,40.380405 211.001160,45.537086 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M210.997238,30.533884 
	C210.168030,31.000427 209.334167,30.964479 208.504410,31.007521 
	C204.719635,31.203856 199.944748,30.135269 197.467728,32.042934 
	C195.509933,33.550724 196.225342,38.717426 196.036362,42.274250 
	C195.886902,45.087440 194.529770,46.062695 191.912827,46.008957 
	C188.586334,45.940651 185.257385,45.992878 181.468201,45.530689 
	C181.006699,40.376740 181.006485,35.685234 181.006256,30.993731 
	C181.006256,30.993731 181.000549,30.999479 180.997131,31.002865 
	C184.629486,31.001642 188.267395,30.917986 191.900330,31.017109 
	C194.876953,31.098328 196.099731,29.755919 196.017120,26.840052 
	C195.923569,23.537476 195.995255,20.230217 195.994324,16.459251 
	C200.685928,15.995457 205.378311,15.997404 210.537231,15.997751 
	C211.000671,20.686689 210.997574,25.377230 210.997238,30.533884 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M16.000484,61.463928 
	C20.688457,60.998287 25.379364,60.999016 30.535105,60.999817 
	C30.999937,60.999897 30.997101,60.997128 30.998722,61.463547 
	C30.998140,66.622055 30.995937,71.314140 30.996603,76.003357 
	C30.999472,76.000481 31.006186,75.993729 31.006186,75.993729 
	C26.313482,75.996330 21.620781,75.998940 16.461329,76.003563 
	C15.997526,71.313820 16.000473,66.622063 16.000484,61.463928 
z"/>
<path fill="#EDEEEE" opacity="1.000000" stroke="none" 
	d="
M90.535912,15.998257 
	C90.589546,18.945030 90.176826,21.893181 88.913177,24.925936 
	C84.049812,25.012545 80.037376,25.014547 76.016350,24.565544 
	C76.006035,21.408823 76.004326,18.703110 76.002609,15.997395 
	C76.002609,15.997395 76.000000,15.999924 75.999237,16.000704 
	C80.688835,16.000868 85.379204,16.000250 90.535912,15.998257 
z"/>
<path fill="#FCFDFC" opacity="1.000000" stroke="none" 
	d="
M75.536674,16.000500 
	C76.004326,18.703110 76.006035,21.408823 76.007294,24.892914 
	C76.002747,26.108381 75.998650,26.545471 75.631546,27.070103 
	C74.523743,27.135057 73.778961,27.112473 72.877625,26.847954 
	C72.132889,27.037748 71.544724,27.469477 70.958801,27.940138 
	C70.961052,27.979071 70.885963,27.957981 70.662682,28.032486 
	C70.156502,28.290823 69.995178,28.550266 69.970001,28.921097 
	C69.984566,28.956873 69.907333,28.958832 69.517540,29.058830 
	C69.025429,34.102673 68.923111,39.046513 68.898697,44.403633 
	C68.934593,46.213284 68.892586,47.609653 68.621590,49.194603 
	C68.537376,50.253113 68.682159,51.123043 68.907387,52.440475 
	C69.032745,55.915829 69.077652,58.943684 68.836533,61.965401 
	C68.360023,61.968662 68.169533,61.978054 67.720428,61.881096 
	C66.972725,61.506180 66.483627,61.237610 65.995163,60.550529 
	C65.997093,55.422287 65.998390,50.712555 66.295349,45.712635 
	C66.727798,42.616711 66.884758,39.811779 66.996628,37.005043 
	C67.161934,32.857361 65.145592,30.856762 60.999489,30.999695 
	C61.000000,31.000000 60.999847,30.999771 60.997551,30.533665 
	C60.997833,25.376522 61.000412,20.685484 61.002987,15.994447 
	C61.002987,15.994447 61.000393,15.999855 60.997581,16.001331 
	C65.686752,16.003075 70.378746,16.003340 75.536674,16.000500 
z"/>
<path fill="#EDEEEE" opacity="1.000000" stroke="none" 
	d="
M60.536572,15.997931 
	C61.000412,20.685484 60.997833,25.376522 60.531441,30.533346 
	C55.376266,30.997322 50.684902,30.995510 45.996506,30.996572 
	C45.999470,30.999447 46.006237,31.006344 46.006233,31.006344 
	C46.004501,26.314575 46.002766,21.622805 45.999680,16.464703 
	C50.688934,15.999385 55.379547,16.000399 60.536572,15.997931 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M181.000000,91.000153 
	C180.999588,95.690941 180.999161,100.381584 180.996490,105.538971 
	C180.994247,106.005714 181.000366,105.999519 180.536285,105.997559 
	C175.380859,105.997665 170.689514,105.999748 166.000443,105.536591 
	C166.003281,101.767311 166.075790,98.461243 165.982849,95.159836 
	C165.900803,92.246025 167.120956,90.901077 170.097305,90.983131 
	C173.728943,91.083244 177.365540,91.002319 181.000000,90.999695 
	C181.000000,90.999695 181.000000,91.000000 181.000000,91.000153 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M180.993759,121.006302 
	C185.686417,121.003357 190.379089,121.000404 195.538483,120.996124 
	C196.003036,125.686256 196.000870,130.377716 195.998444,135.535507 
	C195.998169,136.001831 196.000000,136.000000 195.534576,136.003235 
	C190.377533,136.006226 185.685898,136.005981 180.997284,135.538971 
	C181.002319,130.379349 181.004349,125.686478 181.003479,120.996521 
	C181.000565,120.999443 180.993759,121.006302 180.993759,121.006302 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M165.998169,106.001831 
	C170.689514,105.999748 175.380859,105.997665 180.538986,105.994919 
	C181.002243,110.687057 180.998718,115.379860 180.994476,120.539490 
	C180.993759,121.006302 181.000565,120.999443 180.536621,120.999283 
	C175.380066,121.001160 170.687439,121.003189 165.996979,120.538528 
	C166.000031,115.380608 166.000931,110.689392 166.000916,105.999084 
	C166.000000,106.000000 165.998169,106.001831 165.998169,106.001831 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M61.006218,180.993591 
	C56.313129,180.995239 51.620045,180.996872 46.463722,180.999481 
	C46.000492,181.000443 46.006008,181.006012 46.005318,180.540329 
	C46.005466,176.770966 46.078506,173.465271 45.985485,170.164230 
	C45.903267,167.246582 47.139874,165.907288 50.105019,165.984390 
	C53.736595,166.078796 57.372513,166.006195 61.003277,166.002899 
	C61.000000,166.000000 60.994148,165.993439 60.995544,166.460205 
	C60.995907,171.619995 60.994881,176.313004 60.996719,181.003235 
	C60.999592,181.000458 61.006218,180.993607 61.006218,180.993591 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M31.459377,196.003052 
	C30.996742,191.313263 30.999748,186.620285 31.004515,181.460510 
	C31.006273,180.993683 30.999540,181.000488 31.463486,181.002563 
	C36.620289,181.005096 41.313148,181.005554 46.006008,181.006012 
	C46.006008,181.006012 46.000492,181.000443 45.997200,180.997162 
	C45.994267,184.627441 45.925713,188.262726 46.016563,191.894012 
	C46.090611,194.853455 44.752892,196.096100 41.834076,196.014221 
	C38.533520,195.921646 35.228245,195.997345 31.459377,196.003052 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M69.261383,184.984558 
	C69.195717,187.366653 69.130058,189.748764 68.984680,192.571198 
	C66.545090,194.009827 64.185219,195.008102 61.409454,196.006012 
	C60.996918,191.312851 61.000271,186.620056 61.004921,181.460419 
	C61.006218,180.993607 60.999592,181.000458 61.401791,181.003082 
	C64.170700,181.333786 66.537415,181.661850 68.991653,182.358246 
	C69.139908,183.479263 69.200645,184.231903 69.261383,184.984558 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M181.001129,181.466125 
	C185.690201,180.999557 190.380386,180.999115 195.537125,180.997711 
	C196.000214,184.630417 195.924103,188.265945 196.016037,191.897186 
	C196.091095,194.861420 194.753174,196.097427 191.835449,196.016098 
	C188.534760,195.924133 185.229370,196.000900 181.460434,196.005066 
	C180.997314,191.314636 180.999786,186.623444 181.001129,181.466125 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M180.997253,165.997253 
	C180.995148,170.685104 180.996323,175.376205 180.998444,180.533340 
	C176.308502,180.998993 171.617615,180.998596 166.463638,180.999344 
	C166.000549,181.000488 166.005859,181.006424 166.005264,180.540710 
	C166.005478,176.771317 166.078430,173.465637 165.985428,170.164612 
	C165.903259,167.247345 167.139099,165.907211 170.104599,165.984390 
	C173.736145,166.078903 177.372055,166.006454 181.006073,166.006180 
	C181.006073,166.006180 181.000519,166.000504 180.997253,165.997253 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M165.997131,180.997345 
	C165.995392,184.133377 166.314301,187.317215 165.880646,190.395035 
	C165.617432,192.263107 164.190842,193.967255 163.286911,195.745041 
	C161.783859,194.875778 160.280823,194.006531 158.317291,192.662903 
	C157.923035,189.123581 157.989258,186.058594 158.425018,182.955368 
	C161.198334,182.280212 163.602097,181.643326 166.005859,181.006439 
	C166.005859,181.006424 166.000549,181.000488 165.997131,180.997345 
z"/>
<path fill="#292929" opacity="1.000000" stroke="none" 
	d="
M158.055481,182.993622 
	C157.989258,186.058594 157.923035,189.123581 157.965256,192.598114 
	C157.667358,194.341461 157.261017,195.675262 156.515533,197.128204 
	C156.118271,189.849121 155.980881,182.450302 156.036591,175.052933 
	C156.063049,171.537598 156.440201,168.030563 153.008163,164.968933 
	C153.027679,163.754089 153.052902,163.252274 153.431793,162.816315 
	C155.114349,163.611984 156.443237,164.341812 157.754517,165.425598 
	C157.843109,171.517563 157.949310,177.255600 158.055481,182.993622 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M31.002571,31.466324 
	C35.691036,31.002380 40.381844,31.004532 45.539444,31.006512 
	C46.006237,31.006344 45.999470,30.999447 45.995987,31.462280 
	C45.993156,35.228539 45.921867,38.534016 46.016033,41.834774 
	C46.099300,44.753433 44.860466,46.089172 41.896160,46.012463 
	C38.264874,45.918499 34.629211,45.993504 30.995440,45.995449 
	C30.995440,45.995449 30.999748,45.999725 31.002178,46.002174 
	C31.004711,41.313885 31.004814,36.623154 31.002571,31.466324 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M88.831627,135.982193 
	C88.879410,140.685852 88.927200,145.389496 88.452736,150.549515 
	C74.646515,151.191422 76.045815,149.086624 75.652740,161.781830 
	C74.532684,161.993256 73.782501,162.104752 72.723984,162.219315 
	C72.009514,162.273163 71.603371,162.323944 71.040573,162.109711 
	C70.360954,161.832855 69.838013,161.820984 69.315063,161.809113 
	C69.484497,162.510757 69.653931,163.212418 69.814194,164.213562 
	C69.691772,164.677612 69.578537,164.842163 69.080521,165.016907 
	C66.128548,165.349197 63.561348,165.671326 60.994148,165.993439 
	C60.994148,165.993439 61.000000,166.000000 61.004768,165.536163 
	C61.006660,160.381958 61.003788,155.691589 61.465847,151.002472 
	C65.235466,151.000198 68.542503,150.917892 71.844131,151.016724 
	C74.774544,151.104446 76.095535,149.859390 76.018059,146.898346 
	C75.922997,143.265671 75.994148,139.628662 76.455353,135.997528 
	C80.888702,135.995102 84.860168,135.988647 88.831627,135.982193 
z"/>
<path fill="#FBECEA" opacity="1.000000" stroke="none" 
	d="
M148.942932,32.975868 
	C149.386658,34.203396 149.830383,35.430923 150.214935,37.018555 
	C150.129471,37.657913 149.999390,37.874939 149.275818,38.168518 
	C148.569824,38.650040 148.353531,38.992794 148.137238,39.335541 
	C147.391525,40.171928 146.645828,41.008316 145.347046,42.075157 
	C144.574631,42.654484 144.355286,43.003353 144.135941,43.352219 
	C143.079147,44.516117 142.022369,45.680012 140.312973,46.964516 
	C139.144562,47.804653 138.628769,48.524189 138.112976,49.243721 
	C133.674210,53.873894 129.235458,58.504066 124.325882,63.560837 
	C121.196999,66.967361 118.538940,69.947296 115.880882,72.927231 
	C115.880882,72.927231 115.964561,72.976837 115.606789,73.008621 
	C113.959106,74.051476 112.581970,74.969955 111.393829,76.089081 
	C105.044525,82.069588 98.742432,88.100220 92.052704,94.062820 
	C91.114143,93.311989 90.546890,92.611839 90.092613,91.615479 
	C91.273300,90.052452 92.341019,88.785629 93.418457,87.471825 
	C93.428169,87.424835 93.523689,87.415573 93.792236,87.293762 
	C94.698349,86.439110 95.335922,85.706261 95.986748,84.986710 
	C96.000000,85.000000 95.974991,84.972130 96.314331,84.957016 
	C97.564232,83.801529 98.474792,82.661156 99.390320,81.475266 
	C99.395287,81.429749 99.485283,81.446609 99.784683,81.333679 
	C101.375397,79.793579 102.666718,78.366417 103.974915,76.967667 
	C103.991798,76.996078 103.942223,76.951927 104.288567,76.919960 
	C106.066620,75.234444 107.498322,73.580894 108.965012,71.963364 
	C109.000000,71.999390 108.926979,71.930756 109.221542,71.825790 
	C110.331444,70.795792 111.146790,69.870758 111.980156,68.972252 
	C111.998177,68.998779 111.953850,68.952171 112.214294,68.915359 
	C112.651184,68.590874 112.827637,68.303200 113.156059,67.937126 
	C113.308037,67.858719 113.428879,67.538757 113.433754,67.489502 
	C113.438629,67.440247 113.535210,67.418556 113.811569,67.254608 
	C119.198845,61.882042 124.309753,56.673420 129.283966,51.319202 
	C129.147247,51.173611 129.454498,51.428848 129.767380,51.344646 
	C130.605438,50.595379 131.130600,49.930317 131.759369,49.248177 
	C131.862946,49.231106 131.954346,49.042061 132.245575,48.996445 
	C132.657257,48.618423 132.777695,48.286018 133.003571,47.805878 
	C133.109009,47.658138 133.094360,47.295418 133.449326,47.230141 
	C135.263016,45.778831 136.721741,44.392807 138.462631,42.981853 
	C139.151733,42.615993 139.558655,42.275066 139.982895,41.963852 
	C140.000198,41.993565 139.939468,41.961300 140.173538,41.879162 
	C140.702133,41.613792 140.900650,41.356476 141.108276,40.764687 
	C141.301727,40.339241 141.390045,40.174187 141.725311,39.980148 
	C142.309570,39.624287 142.646912,39.297409 143.035736,38.654327 
	C143.152420,37.915474 143.217621,37.492828 143.504364,37.051201 
	C143.725906,37.032215 144.170212,37.052097 144.449265,37.022461 
	C145.147842,36.667442 145.567368,36.342064 146.042755,35.738983 
	C146.186569,35.294182 146.274551,35.127083 146.499268,34.899288 
	C146.806625,34.717228 146.915512,34.554531 147.206512,34.164791 
	C147.947891,33.644680 148.445404,33.310272 148.942932,32.975868 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M138.192474,106.979462 
	C138.135895,101.632889 138.079315,96.286324 138.451996,90.519089 
	C142.923080,90.397171 146.964874,90.695915 151.007004,91.461365 
	C151.006989,96.620461 151.006622,101.312866 150.551239,106.003868 
	C146.128281,106.328133 142.160385,106.653793 138.192474,106.979462 
z"/>
<path fill="#EBEBEB" opacity="1.000000" stroke="none" 
	d="
M150.969696,65.316208 
	C152.613068,64.874489 154.256454,64.432770 156.220123,63.562729 
	C156.782822,62.906944 157.067352,62.824047 157.805191,62.877056 
	C160.812439,62.248707 163.408493,61.629025 166.002014,61.004166 
	C165.999451,60.998989 165.990479,60.995174 165.990921,61.460670 
	C165.991486,65.228088 165.922592,68.531898 166.012466,71.831383 
	C166.091751,74.742439 164.857849,76.087265 161.892654,76.010628 
	C158.263016,75.916824 154.629013,75.991829 150.998291,75.997070 
	C150.999695,76.000305 151.006195,76.003181 151.003250,75.550949 
	C150.990097,71.837883 150.979904,68.577049 150.969696,65.316208 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M89.168732,107.992577 
	C89.105995,112.028259 89.043251,116.063950 88.455215,120.551697 
	C83.951546,121.004211 79.973190,121.004662 75.996262,120.538422 
	C75.998055,115.380852 75.998413,110.689964 76.460480,106.001404 
	C80.896324,105.997810 84.870476,105.991898 88.919434,106.317551 
	C89.052406,107.096924 89.110565,107.544754 89.168732,107.992577 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M138.155869,151.015167 
	C138.116379,146.313049 138.076889,141.610962 138.558640,136.454956 
	C143.054962,135.998978 147.030045,135.996918 151.004608,136.461548 
	C151.002441,141.623138 151.000809,146.318024 150.998825,151.006927 
	C150.998489,151.000931 151.012573,150.999298 150.550171,150.998413 
	C146.110458,151.003387 142.133163,151.009277 138.155869,151.015167 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M75.994827,121.005112 
	C79.973190,121.004662 83.951546,121.004211 88.382401,121.010170 
	C88.881691,125.698395 88.928482,130.380219 88.903442,135.522110 
	C84.860168,135.988647 80.888702,135.995102 76.458923,136.001068 
	C76.000603,136.000595 76.006615,136.006683 76.003571,135.539825 
	C76.002075,130.380234 76.003632,125.687500 76.002777,120.997200 
	C76.000366,120.999641 75.994827,121.005112 75.994827,121.005112 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M151.005127,135.994858 
	C147.030045,135.996918 143.054962,135.998978 138.628113,135.992249 
	C138.122269,131.301300 138.068207,126.619179 138.539886,121.469666 
	C143.045731,121.003464 147.025803,121.004654 151.002609,121.002609 
	C150.999329,120.999374 150.993698,120.993584 150.995331,121.460419 
	C150.995789,126.620369 150.994598,131.313477 150.996307,136.003693 
	C150.999207,136.000793 151.005127,135.994858 151.005127,135.994858 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M151.005890,121.005844 
	C147.025803,121.004654 143.045731,121.003464 138.612823,121.009727 
	C138.120041,116.645103 138.080048,112.273018 138.116272,107.440201 
	C142.160385,106.653793 146.128281,106.328133 150.547882,106.001007 
	C150.999573,105.999550 150.994659,105.993622 150.996033,106.460388 
	C151.000244,111.620056 151.003067,116.312950 151.005890,121.005844 
z"/>
<path fill="#333637" opacity="1.000000" stroke="none" 
	d="
M89.524460,107.908127 
	C89.110565,107.544754 89.052406,107.096924 88.996872,105.890793 
	C87.906189,100.764946 90.303902,97.482368 92.424004,94.113510 
	C98.742432,88.100220 105.044525,82.069588 111.393829,76.089081 
	C112.581970,74.969955 113.959106,74.051476 115.589142,72.963287 
	C116.367981,73.863945 116.676971,74.932106 117.278984,75.796082 
	C117.994904,76.823524 118.962578,77.675545 119.821159,78.603577 
	C118.297203,79.147385 116.722885,79.582253 115.260124,80.258438 
	C112.499725,81.534470 108.996498,82.309631 107.285042,84.468597 
	C105.148376,87.163956 100.727165,89.932762 104.087143,94.533630 
	C101.528740,94.994446 98.934082,95.132584 96.581108,95.958916 
	C92.549194,97.374870 92.267647,101.449493 91.037300,104.797417 
	C90.664803,105.811020 90.266609,106.815186 89.524460,107.908127 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M151.006668,90.994659 
	C146.964874,90.695915 142.923080,90.397171 138.535614,90.059395 
	C138.137924,85.980011 138.085892,81.939667 138.509888,77.383530 
	C142.992691,76.579559 146.999435,76.291367 151.006195,76.003181 
	C151.006195,76.003181 150.999695,76.000305 151.000916,76.463882 
	C150.999588,81.620613 150.997040,86.313759 150.996918,91.003677 
	C150.999344,91.000443 151.006668,90.994652 151.006668,90.994659 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M151.003250,75.550949 
	C146.999435,76.291367 142.992691,76.579559 138.587372,76.924561 
	C138.124710,73.623123 138.060608,70.264870 138.256500,66.376549 
	C138.752136,65.706116 138.914200,65.506104 139.002686,65.246475 
	C142.690262,65.214180 146.377838,65.181885 150.517548,65.232910 
	C150.979904,68.577049 150.990097,71.837883 151.003250,75.550949 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M138.077759,151.462799 
	C142.133163,151.009277 146.110458,151.003387 150.550568,151.450409 
	C150.996643,155.165054 150.979889,158.426834 150.512711,161.762695 
	C146.709183,161.906952 143.356064,161.977112 140.002960,162.047272 
	C139.334000,160.475082 138.387817,158.956314 138.071808,157.316116 
	C137.736221,155.574249 137.998459,153.717194 138.077759,151.462799 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M76.022614,161.681885 
	C76.045815,149.086624 74.646515,151.191422 88.380539,151.011978 
	C88.870377,154.305069 88.910156,157.592056 88.479393,161.362686 
	C84.013435,161.791519 80.018028,161.736710 76.022614,161.681885 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M60.995544,166.460220 
	C63.561348,165.671326 66.128548,165.349197 69.032364,165.347412 
	C69.387840,165.889648 69.406700,166.111542 69.440941,166.375290 
	C69.456329,166.417160 69.404823,166.491028 69.293304,166.909943 
	C69.211792,170.547989 69.241791,173.767151 69.271790,176.986298 
	C69.201965,178.063141 69.132141,179.139999 69.062401,180.858246 
	C69.009697,181.663071 68.956917,181.826492 68.904129,181.989899 
	C66.537415,181.661850 64.170700,181.333786 61.398918,181.005859 
	C60.994881,176.313004 60.995907,171.619995 60.995544,166.460220 
z"/>
<path fill="#313131" opacity="1.000000" stroke="none" 
	d="
M69.425552,166.333420 
	C69.406700,166.111542 69.387840,165.889648 69.417145,165.337250 
	C69.578537,164.842163 69.691772,164.677612 69.997360,164.185791 
	C70.189713,163.858536 70.554169,163.791718 70.889763,163.827393 
	C71.827682,163.314117 72.430000,162.765182 73.032318,162.216232 
	C73.782501,162.104752 74.532684,161.993256 75.652740,161.781830 
	C80.018028,161.736710 84.013435,161.791519 88.465271,161.805756 
	C88.245827,166.634903 83.987411,165.274841 81.176666,165.861206 
	C78.170113,166.488419 74.943092,166.058777 70.978836,166.134857 
	C69.905434,166.230652 69.665489,166.282043 69.425552,166.333420 
z"/>
<path fill="#2F302E" opacity="1.000000" stroke="none" 
	d="
M157.394012,62.885719 
	C157.067352,62.824047 156.782822,62.906944 156.322540,63.205963 
	C156.068924,57.708004 155.909653,52.135471 156.045746,46.570160 
	C156.122253,43.441944 156.686600,40.325661 157.369751,37.112209 
	C157.765503,38.089466 157.822632,39.158516 157.902130,41.080933 
	C157.747665,48.918110 157.570831,55.901917 157.394012,62.885719 
z"/>
<path fill="#333637" opacity="1.000000" stroke="none" 
	d="
M116.294502,72.946640 
	C118.538940,69.947296 121.196999,66.967361 124.143631,63.853302 
	C125.273743,64.879601 126.559868,65.960609 126.786789,67.230827 
	C126.918762,67.969574 125.521172,69.359528 124.516006,69.849068 
	C122.003349,71.072784 119.321861,71.949875 116.294502,72.946640 
z"/>
<path fill="#313131" opacity="1.000000" stroke="none" 
	d="
M139.952209,162.367950 
	C143.356064,161.977112 146.709183,161.906952 150.814453,161.865616 
	C152.070450,162.179794 152.574295,162.465134 153.078125,162.750473 
	C153.052902,163.252274 153.027679,163.754089 152.996384,164.632904 
	C148.284271,165.009918 143.578247,165.009918 138.671097,165.009918 
	C138.973511,164.439377 139.437485,163.564011 139.952209,162.367950 
z"/>
<path fill="#313131" opacity="1.000000" stroke="none" 
	d="
M69.642395,176.870453 
	C69.241791,173.767151 69.211792,170.547989 69.651718,167.033813 
	C70.373291,166.742844 70.624924,166.746902 70.876564,166.750977 
	C70.588707,170.085526 70.300850,173.420059 69.642395,176.870453 
z"/>
<path fill="#313131" opacity="1.000000" stroke="none" 
	d="
M68.991653,182.358246 
	C68.956917,181.826492 69.009697,181.663071 69.147186,181.256363 
	C69.454201,182.286163 69.676521,183.559250 69.580109,184.908447 
	C69.200645,184.231903 69.139908,183.479263 68.991653,182.358246 
z"/>
<path fill="#F7E6E3" opacity="1.000000" stroke="none" 
	d="
M138.415497,49.122238 
	C138.628769,48.524189 139.144562,47.804653 140.043518,47.076038 
	C139.857117,47.711555 139.287567,48.356155 138.415497,49.122238 
z"/>
<path fill="#F7E6E3" opacity="1.000000" stroke="none" 
	d="
M144.415192,43.231194 
	C144.355286,43.003353 144.574631,42.654484 145.102661,42.222248 
	C145.172379,42.462643 144.933411,42.786404 144.415192,43.231194 
z"/>
<path fill="#EBEBEB" opacity="1.000000" stroke="none" 
	d="
M138.745544,65.186623 
	C138.914200,65.506104 138.752136,65.706116 138.336441,65.924950 
	C138.267059,65.711197 138.377731,65.418983 138.745544,65.186623 
z"/>
<path fill="#F7E6E3" opacity="1.000000" stroke="none" 
	d="
M148.423737,39.231190 
	C148.353531,38.992794 148.569824,38.650040 149.090393,38.228531 
	C149.166534,38.475460 148.938385,38.801147 148.423737,39.231190 
z"/>
<path fill="#494949" opacity="1.000000" stroke="none" 
	d="
M70.890564,166.618637 
	C70.624924,166.746902 70.373291,166.742844 69.763237,166.614899 
	C69.404823,166.491028 69.456329,166.417160 69.440941,166.375290 
	C69.665489,166.282043 69.905434,166.230652 70.511475,166.200394 
	C70.877579,166.221527 70.904556,166.486298 70.890564,166.618637 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M157.805191,62.877056 
	C157.570831,55.901917 157.747665,48.918110 157.999084,41.471474 
	C158.714478,41.806698 159.302170,42.655949 160.009583,43.389782 
	C160.883011,44.295826 162.177292,45.981117 162.721344,45.781399 
	C163.985672,45.317265 165.700974,44.011166 165.824417,42.891014 
	C166.223099,39.273380 165.985352,35.585606 165.993423,31.459192 
	C170.686874,30.993767 175.379761,30.994009 180.539459,30.993992 
	C181.006485,35.685234 181.006699,40.376740 181.003159,45.534428 
	C180.999390,46.000610 181.000000,46.000000 180.535217,45.999992 
	C176.766846,46.002079 173.461197,46.077229 170.160294,45.984383 
	C167.238678,45.902206 165.908279,47.147263 165.984604,50.108116 
	C166.078217,53.739616 166.005417,57.375404 166.004562,61.009338 
	C163.408493,61.629025 160.812439,62.248707 157.805191,62.877056 
z"/>
<path fill="#C3191A" opacity="1.000000" stroke="none" 
	d="
M69.122559,61.971539 
	C69.077652,58.943684 69.032745,55.915829 69.020073,52.076103 
	C68.985062,50.511494 68.917824,49.758755 68.850586,49.006020 
	C68.892586,47.609653 68.934593,46.213284 69.001686,43.952003 
	C69.320297,38.377674 69.613815,33.668251 69.907333,28.958832 
	C69.907333,28.958832 69.984566,28.956873 70.202652,28.854847 
	C70.745255,28.587063 70.900337,28.322117 70.885963,27.957981 
	C70.885963,27.957981 70.961052,27.979071 71.251167,27.934994 
	C72.038918,27.623909 72.536545,27.356899 73.034180,27.089890 
	C73.778961,27.112473 74.523743,27.135057 76.083466,27.184307 
	C80.937523,27.120441 84.976646,27.029911 89.015762,26.939381 
	C91.031715,27.035778 93.047668,27.132174 95.597450,27.442314 
	C96.446274,27.539604 96.761269,27.423153 97.076263,27.306698 
	C99.425171,27.254587 101.774078,27.202477 105.012100,27.183060 
	C110.938950,27.129423 115.976669,27.043095 121.014397,26.956764 
	C126.032570,27.016445 131.050735,27.076128 136.973221,27.161484 
	C141.227097,27.234156 144.576645,27.281155 148.097580,27.515863 
	C148.874664,27.582146 149.480377,27.460722 150.139496,27.223938 
	C150.192917,27.108578 149.989471,26.956091 149.989471,26.956091 
	C150.715393,27.019503 151.441315,27.082916 152.533859,27.116396 
	C151.898727,28.432997 150.896973,29.779531 149.462677,31.077496 
	C149.030151,31.028929 148.856522,30.872807 148.802353,30.483944 
	C147.734665,29.724941 146.721878,29.034008 145.707535,29.031750 
	C122.576019,28.980246 99.444298,29.024916 76.312706,28.992882 
	C72.483177,28.987577 70.809059,30.819490 70.987709,34.518127 
	C71.470985,44.523659 71.976761,54.528099 72.237022,64.610481 
	C71.669151,64.663292 71.338348,64.668549 70.899399,64.381348 
	C70.234703,63.363148 69.678627,62.667343 69.122559,61.971539 
z"/>
<path fill="#FCE5E1" opacity="1.000000" stroke="none" 
	d="
M121.012115,26.634510 
	C115.976669,27.043095 110.938950,27.129423 105.445862,27.098091 
	C105.169083,26.432413 105.347672,25.884396 105.774246,25.176325 
	C110.695671,25.015718 115.369118,25.015165 120.504929,25.019753 
	C120.981476,25.454012 120.995651,25.883133 121.012115,26.634510 
z"/>
<path fill="#FCE5E1" opacity="1.000000" stroke="none" 
	d="
M147.926208,27.328156 
	C144.576645,27.281155 141.227097,27.234156 137.431717,27.071163 
	C136.992935,26.523085 136.999969,26.091000 137.016434,25.335150 
	C141.100922,25.012293 145.175980,25.013199 149.645111,25.012951 
	C150.029327,25.443844 150.019485,25.875893 149.999573,26.632015 
	C149.989471,26.956091 150.192917,27.108578 149.786896,27.102600 
	C148.895996,27.173800 148.411102,27.250978 147.926208,27.328156 
z"/>
<path fill="#C3191A" opacity="1.000000" stroke="none" 
	d="
M148.986725,32.677376 
	C148.445404,33.310272 147.947891,33.644680 147.022064,34.076721 
	C146.593765,34.174351 146.184784,34.177090 146.099380,34.093433 
	C146.013977,34.009777 145.844803,33.840794 145.924133,33.522499 
	C146.954468,32.427074 147.905502,31.649940 148.856522,30.872807 
	C148.856522,30.872807 149.030151,31.028929 149.117325,31.106863 
	C149.146500,31.582825 149.088516,31.980854 148.986725,32.677376 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M45.986706,132.000000 
	C41.259872,131.699127 36.533039,131.398254 31.413666,131.049347 
	C31.013947,127.969322 31.006769,124.937309 31.001682,121.450760 
	C31.003775,120.996223 30.999842,121.000191 31.464577,120.999901 
	C36.621281,120.998009 41.313251,120.996399 46.003277,121.451538 
	C45.996460,125.272194 45.991585,128.636093 45.986706,132.000000 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M31.002182,166.002136 
	C31.005226,161.312057 31.005842,156.619522 31.006218,151.460144 
	C31.005980,150.993301 30.999491,151.000595 31.463200,151.002991 
	C36.617836,151.003799 41.308765,151.002197 45.996780,151.465546 
	C45.993885,155.233200 45.921711,158.537964 46.015549,161.837997 
	C46.098583,164.758163 44.855095,166.088562 41.893719,166.012070 
	C38.263222,165.918304 34.628365,165.993362 30.995392,165.995422 
	C30.995392,165.995422 30.999752,165.999680 31.002182,166.002136 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M45.991821,132.400024 
	C45.991585,128.636093 45.996460,125.272194 46.000488,121.454323 
	C45.999638,121.000359 45.994755,121.005241 46.461464,121.003906 
	C51.619732,121.002663 56.311291,121.002747 61.001427,121.001419 
	C61.000000,121.000000 60.997318,120.997261 61.000114,121.463661 
	C60.999996,126.621971 60.997082,131.313889 60.997086,136.002899 
	C61.000000,136.000000 61.005848,135.994156 60.539146,135.997757 
	C55.381172,136.000504 50.689899,135.999649 45.999313,135.999390 
	C46.000000,136.000000 46.001373,136.001221 46.001373,136.001221 
	C45.999893,134.934158 45.998413,133.867111 45.991821,132.400024 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M45.999695,151.000610 
	C41.308765,151.002197 36.617836,151.003799 31.460054,151.005737 
	C30.998013,147.370926 31.079346,143.733734 30.983702,140.101212 
	C30.905558,137.133347 32.233475,135.898209 35.157578,135.982864 
	C38.459412,136.078461 41.766308,135.999191 45.536186,135.998596 
	C46.001373,136.001221 46.000000,136.000000 46.000778,136.465652 
	C46.001236,141.620667 46.000923,146.310028 46.000305,150.999695 
	C46.000000,151.000000 45.999695,151.000610 45.999695,151.000610 
z"/>
<path fill="#FCE5E1" opacity="1.000000" stroke="none" 
	d="
M89.015198,26.617817 
	C84.976646,27.029911 80.937523,27.120441 76.446487,27.096767 
	C75.998650,26.545471 76.002747,26.108381 76.015892,25.343922 
	C80.037376,25.014547 84.049812,25.012545 88.523254,25.010675 
	C88.994385,25.439291 89.004501,25.867771 89.015198,26.617817 
z"/>
<path fill="#E15B5C" opacity="1.000000" stroke="none" 
	d="
M96.786377,27.206337 
	C96.761269,27.423153 96.446274,27.539604 96.027130,27.490044 
	C96.114151,27.251345 96.305313,27.178659 96.786377,27.206337 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M46.002426,120.997574 
	C41.313251,120.996399 36.621281,120.998009 31.462765,121.001694 
	C30.997116,116.312286 30.998014,111.620804 31.001341,106.462769 
	C31.003769,105.996231 30.999840,106.000198 31.464554,106.000427 
	C36.620140,105.999817 41.311016,105.998993 46.001587,106.464500 
	C45.999107,111.622299 45.996933,116.313774 45.994759,121.005241 
	C45.994755,121.005241 45.999638,121.000359 46.002426,120.997574 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M46.001892,105.998169 
	C41.311016,105.998993 36.620140,105.999817 31.462708,106.002243 
	C30.999815,101.311592 31.003483,96.619354 31.006632,91.460281 
	C31.006115,90.993446 30.999472,91.000580 31.462179,91.002190 
	C35.230362,91.001419 38.538105,90.922211 41.840637,91.019669 
	C44.765640,91.105980 46.097668,89.870628 46.020592,86.903481 
	C45.926208,83.269958 46.001820,79.632034 46.001869,75.998108 
	C45.999809,76.000198 45.995941,76.003960 46.462479,76.005585 
	C51.620838,76.006363 56.312653,76.005508 61.002235,76.002327 
	C61.000000,76.000000 60.995220,75.995438 60.996094,76.459152 
	C60.987003,80.949059 60.977039,84.975235 60.651821,89.043442 
	C59.891281,89.717613 59.445988,90.349762 58.539589,90.987900 
	C55.766350,90.997124 53.440228,91.163666 51.145233,90.966972 
	C47.281059,90.635780 45.686203,92.187241 45.951637,96.095818 
	C46.174919,99.383705 45.997852,102.698792 45.999008,106.000916 
	C46.000000,106.000000 46.001892,105.998169 46.001892,105.998169 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M46.003933,75.996002 
	C46.001820,79.632034 45.926208,83.269958 46.020592,86.903481 
	C46.097668,89.870628 44.765640,91.105980 41.840637,91.019669 
	C38.538105,90.922211 35.230362,91.001419 31.459129,91.005013 
	C30.996885,86.313271 31.000397,81.620316 31.005047,76.460541 
	C31.006186,75.993729 30.999472,76.000481 31.463411,76.000122 
	C36.619545,75.998512 41.311737,75.997261 46.003933,75.996002 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M151.002930,106.002411 
	C151.006622,101.312866 151.006989,96.620461 151.007004,91.461357 
	C151.006668,90.994652 150.999344,91.000443 151.462555,91.002213 
	C155.254333,91.001419 158.584076,90.943405 161.911087,91.014008 
	C164.529022,91.069565 165.890640,90.104599 166.040283,87.287560 
	C166.229294,83.729424 165.511520,78.562141 167.469788,77.052078 
	C169.945923,75.142677 174.722961,76.209595 178.508560,76.011719 
	C179.338440,75.968346 180.172516,76.004906 181.002502,76.002426 
	C181.000336,76.000206 180.995316,75.995285 180.995316,75.995285 
	C180.994949,80.686020 180.994583,85.376762 180.997116,90.533600 
	C177.365540,91.002319 173.728943,91.083244 170.097305,90.983131 
	C167.120956,90.901077 165.900803,92.246025 165.982849,95.159836 
	C166.075790,98.461243 166.003281,101.767311 166.000443,105.536591 
	C165.998169,106.001831 166.000000,106.000000 165.534592,105.998032 
	C160.377670,105.995247 155.686172,105.994438 150.994659,105.993622 
	C150.994659,105.993622 150.999573,105.999550 151.002930,106.002411 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M180.993774,61.006214 
	C185.686020,61.003605 190.378281,61.000999 195.537094,60.997375 
	C196.000153,64.630013 195.923935,68.265503 196.015900,71.896729 
	C196.090973,74.860718 194.753693,76.097633 191.835739,76.015869 
	C188.535065,75.923378 185.229706,75.998940 181.460846,75.998360 
	C180.995316,75.995285 181.000336,76.000206 181.001709,75.535782 
	C181.004166,70.378761 181.005264,65.686150 181.003479,60.996506 
	C181.000580,60.999462 180.993774,61.006214 180.993774,61.006214 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M30.996754,166.462006 
	C34.628365,165.993362 38.263222,165.918304 41.893719,166.012070 
	C44.855095,166.088562 46.098583,164.758163 46.015549,161.837997 
	C45.921711,158.537964 45.993885,155.233200 45.996780,151.465546 
	C45.999695,151.000610 46.000000,151.000000 46.466347,150.996399 
	C51.621437,150.994797 56.310184,150.996780 60.999466,150.999390 
	C61.000000,151.000000 61.000916,151.001221 61.000916,151.001221 
	C61.003788,155.691589 61.006660,160.381958 61.008045,165.539062 
	C57.372513,166.006195 53.736595,166.078796 50.105019,165.984390 
	C47.139874,165.907288 45.903267,167.246582 45.985485,170.164230 
	C46.078506,173.465271 46.005466,176.770966 46.005318,180.540329 
	C41.313148,181.005554 36.620289,181.005096 31.460625,181.005371 
	C30.995253,176.313629 30.996685,171.621109 30.996754,166.462006 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M158.425034,182.955368 
	C157.949310,177.255600 157.843109,171.517563 158.167175,165.405380 
	C165.896194,166.564011 165.987030,166.489136 165.994110,158.892960 
	C165.996567,156.263596 165.995132,153.634247 165.997711,151.002472 
	C165.999939,151.000061 166.000000,151.000000 166.466095,150.996735 
	C171.621262,150.995422 176.310333,150.997406 180.999695,150.999695 
	C181.000000,151.000000 181.000610,151.000610 181.000610,151.000610 
	C181.003372,155.691269 181.006134,160.381943 181.007477,165.539398 
	C177.372055,166.006454 173.736145,166.078903 170.104599,165.984390 
	C167.139099,165.907211 165.903259,167.247345 165.985428,170.164612 
	C166.078430,173.465637 166.005478,176.771317 166.005264,180.540710 
	C163.602097,181.643326 161.198334,182.280212 158.425034,182.955368 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M180.994278,136.005737 
	C185.685898,136.005981 190.377533,136.006226 195.535492,136.002319 
	C195.999557,139.632614 195.924469,143.268921 196.017822,146.900909 
	C196.093964,149.863327 194.769760,151.103714 191.841995,151.016251 
	C188.541000,150.917648 185.234589,150.999664 181.465576,151.001862 
	C181.000610,151.000610 181.000000,151.000000 180.997955,150.533646 
	C180.999207,145.376266 181.002502,140.685226 181.003082,135.996902 
	C181.000397,135.999603 180.994278,136.005737 180.994278,136.005737 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M30.996990,46.462029 
	C34.629211,45.993504 38.264874,45.918499 41.896160,46.012463 
	C44.860466,46.089172 46.099300,44.753433 46.016033,41.834774 
	C45.921867,38.534016 45.993156,35.228539 45.993019,31.459406 
	C50.684902,30.995510 55.376266,30.997322 60.533737,30.999451 
	C60.999847,30.999771 61.000000,31.000000 61.001266,31.465887 
	C61.001129,36.621742 60.999725,41.311710 60.999161,46.000839 
	C61.000000,46.000000 61.000729,45.999275 60.535629,45.999561 
	C56.767586,46.001949 53.462597,46.076977 50.162319,45.984291 
	C47.243481,45.902321 45.909267,47.142250 45.984249,50.104591 
	C46.076149,53.735405 45.999870,57.370468 45.998085,61.001915 
	C45.999863,61.000137 46.003693,60.996307 45.537346,60.998497 
	C40.379696,60.999496 35.688400,60.998310 30.997101,60.997128 
	C30.997101,60.997128 30.999937,60.999897 31.001404,61.001389 
	C31.001427,56.311459 30.999985,51.620037 30.996990,46.462029 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M166.002014,61.004166 
	C166.005417,57.375404 166.078217,53.739616 165.984604,50.108116 
	C165.908279,47.147263 167.238678,45.902206 170.160294,45.984383 
	C173.461197,46.077229 176.766846,46.002079 180.535507,45.999748 
	C180.999130,50.690548 180.997665,55.381592 180.994995,60.539421 
	C180.993774,61.006214 181.000580,60.999462 180.536652,60.997986 
	C175.378647,60.996063 170.684555,60.995617 165.990479,60.995174 
	C165.990479,60.995174 165.999451,60.998989 166.002014,61.004166 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M30.998722,61.463547 
	C35.688400,60.998310 40.379696,60.999496 45.534519,61.465027 
	C45.997341,66.620903 45.996639,71.312431 45.995941,76.003960 
	C45.995941,76.003960 45.999809,76.000198 46.001869,75.998100 
	C41.311737,75.997261 36.619545,75.998512 31.460543,76.002998 
	C30.995937,71.314140 30.998140,66.622055 30.998722,61.463547 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M71.008026,64.703751 
	C71.338348,64.668549 71.669151,64.663292 72.253983,64.619995 
	C72.507545,64.552010 72.537506,64.576843 72.623535,64.742844 
	C72.709557,64.908836 72.936806,65.207619 73.002304,65.509964 
	C74.033546,66.533615 74.999290,67.254921 75.975082,68.416595 
	C75.980087,75.989700 75.980087,75.990509 68.929092,75.996254 
	C66.284470,75.998405 63.639843,75.995842 60.995220,75.995438 
	C60.995220,75.995438 61.000000,76.000000 61.001427,75.535690 
	C61.001694,70.380714 61.000542,65.690056 61.415779,61.000496 
	C63.219624,60.990749 64.607079,60.979893 65.994537,60.969040 
	C66.483627,61.237610 66.972725,61.506180 67.731956,62.223404 
	C68.404495,64.236374 68.083313,66.871262 71.008026,64.703751 
z"/>
<path fill="#EFEFF0" opacity="1.000000" stroke="none" 
	d="
M60.998322,46.001678 
	C60.999725,41.311710 61.001129,36.621742 61.000755,31.465582 
	C65.145592,30.856762 67.161934,32.857361 66.996628,37.005043 
	C66.884758,39.811779 66.727798,42.616711 65.878616,45.709229 
	C63.776936,45.997894 62.387630,45.999786 60.998322,46.001678 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M60.999161,46.000839 
	C62.387630,45.999786 63.776936,45.997894 65.582970,45.999413 
	C65.998390,50.712555 65.997093,55.422287 65.995163,60.550529 
	C64.607079,60.979893 63.219624,60.990749 61.416084,61.000801 
	C61.000000,61.000000 61.000458,61.000458 60.999290,60.534294 
	C60.998989,55.378510 60.999859,50.688892 61.000729,45.999275 
	C61.000729,45.999275 61.000000,46.000000 60.999161,46.000839 
z"/>
<path fill="#FCE5E1" opacity="1.000000" stroke="none" 
	d="
M69.517540,29.058830 
	C69.613815,33.668251 69.320297,38.377674 68.923782,43.538723 
	C68.923111,39.046513 69.025429,34.102673 69.517540,29.058830 
z"/>
<path fill="#FCE5E1" opacity="1.000000" stroke="none" 
	d="
M70.899399,64.381348 
	C68.083313,66.871262 68.404495,64.236374 67.990570,62.329754 
	C68.169533,61.978054 68.360023,61.968662 68.836533,61.965401 
	C69.678627,62.667343 70.234703,63.363148 70.899399,64.381348 
z"/>
<path fill="#FCE5E1" opacity="1.000000" stroke="none" 
	d="
M72.877625,26.847954 
	C72.536545,27.356899 72.038918,27.623909 71.248917,27.896061 
	C71.544724,27.469477 72.132889,27.037748 72.877625,26.847954 
z"/>
<path fill="#FCE5E1" opacity="1.000000" stroke="none" 
	d="
M68.621597,49.194603 
	C68.917824,49.758755 68.985062,50.511494 68.939621,51.628601 
	C68.682159,51.123043 68.537376,50.253113 68.621597,49.194603 
z"/>
<path fill="#FCE5E1" opacity="1.000000" stroke="none" 
	d="
M70.662682,28.032486 
	C70.900337,28.322117 70.745255,28.587063 70.188080,28.819073 
	C69.995178,28.550266 70.156502,28.290823 70.662682,28.032486 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M165.994812,121.005226 
	C170.687439,121.003189 175.380066,121.001160 180.539536,120.996368 
	C181.004349,125.686478 181.002319,130.379349 180.997284,135.538971 
	C180.994278,136.005737 181.000397,135.999603 180.537811,136.000305 
	C175.716812,136.000671 171.358398,136.000336 166.892639,135.781006 
	C166.586792,135.288712 166.326324,135.101639 166.002350,134.536057 
	C166.002258,129.712463 166.003738,125.353615 166.002777,120.997208 
	C166.000366,120.999641 165.994812,121.005226 165.994812,121.005226 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M150.996033,106.460388 
	C155.686172,105.994438 160.377670,105.995247 165.535507,105.997116 
	C166.000931,110.689392 166.000031,115.380608 165.996979,120.538528 
	C165.994812,121.005226 166.000366,120.999641 165.536087,120.998993 
	C160.379105,120.996758 155.686401,120.995171 150.993698,120.993584 
	C150.993698,120.993584 150.999329,120.999374 151.002609,121.002609 
	C151.003067,116.312950 151.000244,111.620056 150.996033,106.460388 
z"/>
<path fill="#EAEAEA" opacity="1.000000" stroke="none" 
	d="
M165.995468,151.004883 
	C165.995132,153.634247 165.996567,156.263596 165.994110,158.892960 
	C165.987030,166.489136 165.896194,166.564011 158.184784,165.051437 
	C156.443237,164.341812 155.114349,163.611984 153.431793,162.816315 
	C152.574295,162.465134 152.070450,162.179794 151.264877,161.791534 
	C150.979889,158.426834 150.996643,155.165054 151.012985,151.451294 
	C151.012573,150.999298 150.998489,151.000931 151.465027,151.006531 
	C156.619537,151.009720 161.307495,151.007309 165.995468,151.004883 
M158.736526,162.475571 
	C159.132339,162.600677 159.523529,162.747543 159.927734,162.833008 
	C160.011139,162.850647 160.142944,162.639450 160.252426,162.533722 
	C159.857071,162.409134 159.461716,162.284546 158.736526,162.475571 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M75.997025,135.997055 
	C75.994148,139.628662 75.922997,143.265671 76.018059,146.898346 
	C76.095535,149.859390 74.774544,151.104446 71.844131,151.016724 
	C68.542503,150.917892 65.235466,151.000198 61.465847,151.002472 
	C61.000916,151.001221 61.000000,151.000000 60.997684,150.533401 
	C60.998859,145.375916 61.002354,140.685043 61.005848,135.994156 
	C61.005848,135.994156 61.000000,136.000000 61.463860,136.002045 
	C66.620689,136.004974 71.313652,136.005829 76.006615,136.006683 
	C76.006615,136.006683 76.000603,136.000595 75.997025,135.997055 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M70.554169,163.791718 
	C70.554169,163.791718 70.189713,163.858536 70.006538,163.886292 
	C69.653931,163.212418 69.484497,162.510757 69.315063,161.809113 
	C69.838013,161.820984 70.360954,161.832855 70.916550,162.343948 
	C70.817513,163.159348 70.685837,163.475540 70.554169,163.791718 
z"/>
<path fill="#494949" opacity="1.000000" stroke="none" 
	d="
M70.889763,163.827393 
	C70.685837,163.475540 70.817513,163.159348 71.073212,162.608948 
	C71.603371,162.323944 72.009514,162.273163 72.723984,162.219315 
	C72.430000,162.765182 71.827682,163.314117 70.889763,163.827393 
z"/>
<path fill="#ED0304" opacity="1.000000" stroke="none" 
	d="
M148.802353,30.483944 
	C147.905502,31.649940 146.954468,32.427074 145.614914,33.635651 
	C144.845261,34.782417 144.464127,35.497738 144.067596,36.426682 
	C144.052185,36.640312 144.170212,37.052097 144.170212,37.052097 
	C144.170212,37.052097 143.725906,37.032215 143.161255,37.046463 
	C142.016373,37.660450 141.436172,38.260193 140.538132,38.939232 
	C139.414993,39.951561 138.609711,40.884583 137.472412,41.910095 
	C135.791733,43.766869 134.443039,45.531143 133.094360,47.295418 
	C133.094360,47.295418 133.109009,47.658138 132.773834,47.915039 
	C132.277206,48.461979 132.115768,48.752018 131.954346,49.042061 
	C131.954346,49.042061 131.862946,49.231106 131.352829,49.200836 
	C130.379974,49.923332 129.917236,50.676090 129.454498,51.428848 
	C129.454498,51.428848 129.147247,51.173611 128.926041,51.200100 
	C124.274811,55.250507 119.768074,59.195232 115.471428,63.356865 
	C114.461815,64.334740 114.161720,66.045151 113.535202,67.418556 
	C113.535210,67.418556 113.438629,67.440247 113.161079,67.385773 
	C112.584373,67.550423 112.285217,67.769554 111.964905,68.229118 
	C111.943756,68.469551 111.953850,68.952171 111.953850,68.952171 
	C111.953850,68.952171 111.998177,68.998779 111.616730,68.970306 
	C110.465851,69.938141 109.696419,70.934448 108.926979,71.930756 
	C108.926979,71.930756 109.000000,71.999390 108.603241,71.971008 
	C106.785072,73.612396 105.363647,75.282166 103.942223,76.951927 
	C103.942223,76.951927 103.991798,76.996078 103.610214,76.967606 
	C101.980850,78.441620 100.733063,79.944115 99.485283,81.446609 
	C99.485283,81.446609 99.395287,81.429749 99.050209,81.355423 
	C97.795090,82.511444 96.885040,83.741791 95.974991,84.972130 
	C95.974991,84.972130 96.000000,85.000000 95.612099,84.987686 
	C94.657356,85.788773 94.090523,86.602173 93.523689,87.415573 
	C93.523689,87.415573 93.428169,87.424835 93.030838,87.321274 
	C91.914848,86.108421 90.689575,85.053871 90.562805,83.880630 
	C89.919540,77.927200 89.561218,71.942978 88.994949,65.812561 
	C88.886833,65.655586 88.842773,65.276741 88.631958,64.918770 
	C83.126595,64.566147 77.832047,64.571495 72.537506,64.576843 
	C72.537506,64.576843 72.507545,64.552010 72.490585,64.542496 
	C71.976761,54.528099 71.470985,44.523659 70.987709,34.518127 
	C70.809059,30.819490 72.483177,28.987577 76.312706,28.992882 
	C99.444298,29.024916 122.576019,28.980246 145.707535,29.031750 
	C146.721878,29.034008 147.734665,29.724941 148.802353,30.483944 
z"/>
<path fill="#FEFEFD" opacity="1.000000" stroke="none" 
	d="
M92.052704,94.062820 
	C90.303902,97.482368 87.906189,100.764946 88.922058,105.559235 
	C84.870476,105.991898 80.896324,105.997810 76.461090,106.001862 
	C76.000000,106.000000 76.000305,106.000610 75.998871,105.534431 
	C75.997787,100.380463 75.998131,95.692673 75.999634,90.751175 
	C76.001854,90.328339 76.002907,90.159203 76.448013,89.996872 
	C80.259438,90.336700 83.626823,90.669724 87.181198,91.255547 
	C87.964409,91.644287 88.560631,91.780228 89.362511,91.908722 
	C89.568176,91.901276 89.979645,91.911697 89.979645,91.911697 
	C90.546890,92.611839 91.114143,93.311989 92.052704,94.062820 
z"/>
<path fill="#C3191A" opacity="1.000000" stroke="none" 
	d="
M113.811569,67.254608 
	C114.161720,66.045151 114.461815,64.334740 115.471428,63.356865 
	C119.768074,59.195232 124.274811,55.250507 129.062744,51.345692 
	C124.309753,56.673420 119.198845,61.882042 113.811569,67.254608 
z"/>
<path fill="#B83031" opacity="1.000000" stroke="none" 
	d="
M90.092613,91.615479 
	C89.979645,91.911697 89.568176,91.901276 89.304947,91.450897 
	C88.934776,85.989090 88.827843,80.977661 88.797020,75.640877 
	C88.828705,74.874634 88.784271,74.433746 88.809975,73.747253 
	C88.832230,73.338226 88.784348,73.174805 88.806519,72.641739 
	C88.833992,71.523773 88.791412,70.775459 88.834183,69.627556 
	C88.980698,68.141823 89.041878,67.055687 89.103058,65.969543 
	C89.561218,71.942978 89.919540,77.927200 90.562805,83.880630 
	C90.689575,85.053871 91.914848,86.108421 93.021118,87.368256 
	C92.341019,88.785629 91.273300,90.052452 90.092613,91.615479 
z"/>
<path fill="#C3191A" opacity="1.000000" stroke="none" 
	d="
M133.449326,47.230141 
	C134.443039,45.531143 135.791733,43.766869 137.565994,41.999084 
	C137.991577,41.995579 138.176086,42.176300 138.155548,42.384033 
	C138.135025,42.591770 138.180466,43.006783 138.180466,43.006783 
	C136.721741,44.392807 135.263016,45.778831 133.449326,47.230141 
z"/>
<path fill="#B83031" opacity="1.000000" stroke="none" 
	d="
M104.288567,76.919960 
	C105.363647,75.282166 106.785072,73.612396 108.568253,71.934982 
	C107.498322,73.580894 106.066620,75.234444 104.288567,76.919960 
z"/>
<path fill="#B83031" opacity="1.000000" stroke="none" 
	d="
M99.784683,81.333679 
	C100.733063,79.944115 101.980850,78.441620 103.593330,76.939186 
	C102.666718,78.366417 101.375397,79.793579 99.784683,81.333679 
z"/>
<path fill="#B83031" opacity="1.000000" stroke="none" 
	d="
M96.314331,84.957016 
	C96.885040,83.741791 97.795090,82.511444 99.045242,81.400940 
	C98.474792,82.661156 97.564232,83.801529 96.314331,84.957016 
z"/>
<path fill="#C3191A" opacity="1.000000" stroke="none" 
	d="
M137.897995,41.906593 
	C138.609711,40.884583 139.414993,39.951561 140.625122,39.023678 
	C141.029938,39.028820 141.203659,39.197937 141.231720,39.414490 
	C141.259796,39.631046 141.478378,40.009136 141.478378,40.009136 
	C141.390045,40.174187 141.301727,40.339241 140.853729,40.821892 
	C140.309204,41.413425 140.124329,41.687363 139.939468,41.961296 
	C139.939468,41.961300 140.000198,41.993565 139.682678,41.987823 
	C138.968796,42.046825 138.572449,42.111565 138.176086,42.176300 
	C138.176086,42.176300 137.991577,41.995579 137.897995,41.906593 
z"/>
<path fill="#B83031" opacity="1.000000" stroke="none" 
	d="
M109.221542,71.825790 
	C109.696419,70.934448 110.465851,69.938141 111.598709,68.943779 
	C111.146790,69.870758 110.331444,70.795792 109.221542,71.825790 
z"/>
<path fill="#B83031" opacity="1.000000" stroke="none" 
	d="
M93.792236,87.293762 
	C94.090523,86.602173 94.657356,85.788773 95.598846,84.974396 
	C95.335922,85.706261 94.698349,86.439110 93.792236,87.293762 
z"/>
<path fill="#C3191A" opacity="1.000000" stroke="none" 
	d="
M140.942963,38.944374 
	C141.436172,38.260193 142.016373,37.660450 142.939697,37.065445 
	C143.217621,37.492828 143.152420,37.915474 142.737305,38.679092 
	C141.992813,39.079353 141.598236,39.138645 141.203659,39.197937 
	C141.203659,39.197937 141.029938,39.028820 140.942963,38.944374 
z"/>
<path fill="#C3191A" opacity="1.000000" stroke="none" 
	d="
M129.767380,51.344650 
	C129.917236,50.676090 130.379974,49.923332 131.249237,49.217911 
	C131.130600,49.930317 130.605438,50.595379 129.767380,51.344650 
z"/>
<path fill="#C3191A" opacity="1.000000" stroke="none" 
	d="
M144.083008,36.213055 
	C144.464127,35.497738 144.845261,34.782417 145.535583,33.953945 
	C145.844803,33.840794 146.013977,34.009777 146.102005,34.298645 
	C146.190033,34.587513 146.362534,34.959988 146.362534,34.959988 
	C146.274551,35.127083 146.186569,35.294182 145.726013,35.774124 
	C144.929962,36.129002 144.506485,36.171028 144.083008,36.213055 
z"/>
<path fill="#F7E6E3" opacity="1.000000" stroke="none" 
	d="
M141.231720,39.414490 
	C141.598236,39.138645 141.992813,39.079353 142.685822,38.995296 
	C142.646912,39.297409 142.309570,39.624287 141.725311,39.980148 
	C141.478378,40.009136 141.259796,39.631046 141.231720,39.414490 
z"/>
<path fill="#F7E6E3" opacity="1.000000" stroke="none" 
	d="
M138.155548,42.384033 
	C138.572449,42.111565 138.968796,42.046825 139.665375,41.958111 
	C139.558655,42.275066 139.151733,42.615993 138.462631,42.981853 
	C138.180466,43.006783 138.135025,42.591770 138.155548,42.384033 
z"/>
<path fill="#F7E6E3" opacity="1.000000" stroke="none" 
	d="
M144.067596,36.426682 
	C144.506485,36.171028 144.929962,36.129002 145.670166,36.051830 
	C145.567368,36.342064 145.147842,36.667442 144.449265,37.022461 
	C144.170212,37.052097 144.052185,36.640312 144.067596,36.426682 
z"/>
<path fill="#F7E6E3" opacity="1.000000" stroke="none" 
	d="
M140.173538,41.879158 
	C140.124329,41.687363 140.309204,41.413425 140.748627,41.082283 
	C140.900650,41.356476 140.702133,41.613792 140.173538,41.879158 
z"/>
<path fill="#B83031" opacity="1.000000" stroke="none" 
	d="
M112.214294,68.915359 
	C111.953850,68.952171 111.943756,68.469551 112.219475,68.235573 
	C112.664825,68.006248 112.834457,68.010895 113.004082,68.015533 
	C112.827637,68.303200 112.651184,68.590874 112.214294,68.915359 
z"/>
<path fill="#C3191A" opacity="1.000000" stroke="none" 
	d="
M132.245575,48.996445 
	C132.115768,48.752018 132.277206,48.461979 132.668396,48.062775 
	C132.777695,48.286018 132.657257,48.618423 132.245575,48.996445 
z"/>
<path fill="#F7E6E3" opacity="1.000000" stroke="none" 
	d="
M146.499268,34.899288 
	C146.362534,34.959988 146.190033,34.587513 146.187408,34.382301 
	C146.184784,34.177090 146.593765,34.174351 146.778198,34.262421 
	C146.915512,34.554531 146.806625,34.717228 146.499268,34.899288 
z"/>
<path fill="#C3191A" opacity="1.000000" stroke="none" 
	d="
M113.156059,67.937126 
	C112.834457,68.010895 112.664825,68.006248 112.240631,67.995140 
	C112.285217,67.769554 112.584373,67.550423 113.156204,67.435028 
	C113.428879,67.538757 113.308037,67.858719 113.156059,67.937126 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M165.990921,61.460670 
	C170.684555,60.995617 175.378647,60.996063 180.539551,60.995026 
	C181.005264,65.686150 181.004166,70.378761 181.003860,75.538010 
	C180.172516,76.004906 179.338440,75.968346 178.508560,76.011719 
	C174.722961,76.209595 169.945923,75.142677 167.469788,77.052078 
	C165.511520,78.562141 166.229294,83.729424 166.040283,87.287560 
	C165.890640,90.104599 164.529022,91.069565 161.911087,91.014008 
	C158.584076,90.943405 155.254333,91.001419 151.460114,91.005440 
	C150.997040,86.313759 150.999588,81.620613 150.999512,76.460648 
	C154.629013,75.991829 158.263016,75.916824 161.892654,76.010628 
	C164.857849,76.087265 166.091751,74.742439 166.012466,71.831383 
	C165.922592,68.531898 165.991486,65.228088 165.990921,61.460670 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M75.999390,105.999542 
	C75.998413,110.689964 75.998055,115.380852 75.996262,120.538422 
	C75.994827,121.005112 76.000366,120.999641 75.536072,120.998688 
	C70.380287,120.997581 65.688805,120.997421 60.997318,120.997261 
	C60.997318,120.997261 61.000000,121.000000 60.998768,120.534958 
	C61.000267,115.378044 61.002998,110.686180 61.002640,105.997398 
	C60.999557,106.000481 60.994209,106.005798 61.460907,106.002426 
	C66.618500,105.999573 71.309402,106.000092 76.000305,106.000610 
	C76.000305,106.000610 76.000000,106.000000 75.999390,105.999542 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M165.997711,151.002472 
	C161.307495,151.007309 156.619537,151.009720 151.465363,151.012543 
	C151.000809,146.318024 151.002441,141.623138 151.004608,136.461548 
	C151.005127,135.994858 150.999207,136.000793 151.460556,136.003540 
	C156.615906,135.671127 161.309921,135.335968 166.003922,135.000793 
	C166.326324,135.101639 166.586792,135.288712 166.724197,136.161896 
	C166.442062,141.507843 166.221024,146.253922 166.000000,151.000000 
	C166.000000,151.000000 165.999939,151.000061 165.997711,151.002472 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M61.000114,121.463661 
	C65.688805,120.997421 70.380287,120.997581 75.538483,120.996246 
	C76.003632,125.687500 76.002075,130.380234 76.003571,135.539825 
	C71.313652,136.005829 66.620689,136.004974 61.460945,136.004944 
	C60.997082,131.313889 60.999996,126.621971 61.000114,121.463661 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M166.002350,134.536057 
	C161.309921,135.335968 156.615906,135.671127 151.457657,136.006439 
	C150.994598,131.313477 150.995789,126.620369 150.995331,121.460419 
	C155.686401,120.995171 160.379105,120.996758 165.538513,120.996552 
	C166.003738,125.353615 166.002258,129.712463 166.002350,134.536057 
z"/>
<path fill="#E15B5C" opacity="1.000000" stroke="none" 
	d="
M148.097580,27.515865 
	C148.411102,27.250978 148.895996,27.173800 149.733490,27.217960 
	C149.480377,27.460722 148.874664,27.582146 148.097580,27.515865 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M61.005726,105.994316 
	C61.002998,110.686180 61.000267,115.378044 61.000195,120.536369 
	C56.311291,121.002747 51.619732,121.002663 46.461464,121.003906 
	C45.996933,116.313774 45.999107,111.622299 46.001587,106.464500 
	C46.001892,105.998169 46.000000,106.000000 46.465347,105.996773 
	C51.622372,105.993797 56.314049,105.994057 61.005726,105.994316 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M60.539146,135.997757 
	C61.002354,140.685043 60.998859,145.375916 60.997150,150.532776 
	C56.310184,150.996780 51.621437,150.994797 46.466652,150.996094 
	C46.000923,146.310028 46.001236,141.620667 46.000092,136.465042 
	C50.689899,135.999649 55.381172,136.000504 60.539146,135.997757 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M88.739838,73.992867 
	C88.784271,74.433746 88.828705,74.874634 88.509979,75.640686 
	C87.764442,75.982071 87.382072,75.998276 86.545410,76.011452 
	C84.772186,76.007545 83.434425,76.150093 82.137650,75.980087 
	C77.403236,75.359390 75.419014,77.257057 75.950050,82.081932 
	C76.236923,84.688377 76.002960,87.352150 76.003967,89.990059 
	C76.002907,90.159203 76.001854,90.328339 75.533691,90.752708 
	C70.378029,91.005608 65.689476,91.003265 61.000458,91.000458 
	C61.000000,91.000000 60.999390,90.999390 60.995770,90.666344 
	C60.983791,89.889336 60.975430,89.445381 60.967072,89.001419 
	C60.977039,84.975235 60.987003,80.949059 60.996094,76.459152 
	C63.639843,75.995842 66.284470,75.998405 68.929092,75.996254 
	C75.980087,75.990509 75.980087,75.989700 76.039932,68.075928 
	C77.714287,66.669189 79.333847,66.043465 81.335007,65.354416 
	C82.485138,65.342957 83.253654,65.394829 84.114029,65.581902 
	C84.205887,65.717102 84.666107,65.757187 84.666107,65.757187 
	C84.666107,65.757187 84.986328,65.414894 85.309380,65.332710 
	C86.070190,65.304573 86.507950,65.358620 86.930069,65.574860 
	C86.914429,65.737061 86.967506,66.058617 86.980820,66.415970 
	C87.579041,67.857933 88.163940,68.942535 88.748840,70.027145 
	C88.791412,70.775459 88.833992,71.523773 88.666298,72.734482 
	C88.550629,73.462212 88.645233,73.727539 88.739838,73.992867 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M60.999695,60.999695 
	C61.000542,65.690056 61.001694,70.380714 61.003662,75.538017 
	C56.312653,76.005508 51.620838,76.006363 46.462479,76.005585 
	C45.996639,71.312431 45.997341,66.620903 46.000870,61.462841 
	C46.003693,60.996307 45.999863,61.000137 46.464615,61.000908 
	C51.619732,61.001274 56.310093,61.000866 61.000458,61.000458 
	C61.000458,61.000458 61.000000,61.000000 60.999695,60.999695 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M61.002640,105.997398 
	C56.314049,105.994057 51.622372,105.993797 46.464355,105.997688 
	C45.997852,102.698792 46.174919,99.383705 45.951637,96.095818 
	C45.686203,92.187241 47.281059,90.635780 51.145233,90.966972 
	C53.440228,91.163666 55.766350,90.997124 58.872658,90.993469 
	C60.111019,90.995163 60.555206,90.997276 60.999390,90.999390 
	C60.999390,90.999390 61.000000,91.000000 61.002075,91.466476 
	C61.000839,96.623901 60.997524,101.314850 60.994209,106.005798 
	C60.994209,106.005798 60.999557,106.000481 61.002640,105.997398 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M60.995770,90.666344 
	C60.555206,90.997276 60.111019,90.995163 59.333767,90.987473 
	C59.445988,90.349762 59.891281,89.717613 60.651821,89.043442 
	C60.975430,89.445381 60.983791,89.889336 60.995770,90.666344 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M166.466095,150.996735 
	C166.221024,146.253922 166.442062,141.507843 166.831543,136.380890 
	C171.358398,136.000336 175.716812,136.000671 180.540497,135.997604 
	C181.002502,140.685226 180.999207,145.376266 180.997650,150.533340 
	C176.310333,150.997406 171.621262,150.995422 166.466095,150.996735 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M60.999290,60.534294 
	C56.310093,61.000866 51.619732,61.001274 46.462837,61.002689 
	C45.999870,57.370468 46.076149,53.735405 45.984249,50.104591 
	C45.909267,47.142250 47.243481,45.902321 50.162319,45.984291 
	C53.462597,46.076977 56.767586,46.001949 60.535629,45.999561 
	C60.999859,50.688892 60.998989,55.378510 60.999290,60.534294 
z"/>
<path fill="#C3191A" opacity="1.000000" stroke="none" 
	d="
M84.022179,65.446701 
	C83.253654,65.394829 82.485138,65.342957 80.904694,65.241013 
	C77.707458,65.196503 75.322128,65.202065 72.936806,65.207619 
	C72.936806,65.207619 72.709557,64.908836 72.623535,64.742844 
	C77.832047,64.571495 83.126595,64.566147 88.398560,64.951157 
	C88.375977,65.341515 87.922554,65.471725 87.683517,65.371353 
	C87.278229,65.318207 87.111969,65.365433 86.945709,65.412666 
	C86.507950,65.358620 86.070190,65.304573 85.065643,65.258408 
	C84.339966,65.326424 84.181076,65.386559 84.022179,65.446701 
z"/>
<path fill="#FCE5E1" opacity="1.000000" stroke="none" 
	d="
M73.002304,65.509964 
	C75.322128,65.202065 77.707458,65.196503 80.523094,65.304352 
	C79.333847,66.043465 77.714287,66.669189 76.029877,67.635567 
	C74.999290,67.254921 74.033546,66.533615 73.002304,65.509964 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M158.901443,162.317780 
	C159.461716,162.284546 159.857071,162.409134 160.252426,162.533722 
	C160.142944,162.639450 160.011139,162.850647 159.927734,162.833008 
	C159.523529,162.747543 159.132339,162.600677 158.901443,162.317780 
z"/>
<path fill="#FBECEA" opacity="1.000000" stroke="none" 
	d="
M87.922554,65.471725 
	C87.922554,65.471725 88.375977,65.341515 88.609375,65.309128 
	C88.842773,65.276741 88.886833,65.655586 88.994942,65.812561 
	C89.041878,67.055687 88.980698,68.141823 88.834183,69.627556 
	C88.163940,68.942535 87.579041,67.857933 87.163597,66.356552 
	C87.581985,65.849770 87.778488,65.693748 87.922554,65.471725 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M61.460907,106.002426 
	C60.997524,101.314850 61.000839,96.623901 61.002533,91.466934 
	C65.689476,91.003265 70.378029,91.005608 75.532532,91.006416 
	C75.998131,95.692673 75.997787,100.380463 75.998871,105.534431 
	C71.309402,106.000092 66.618500,105.999573 61.460907,106.002426 
z"/>
<path fill="#EDEDED" opacity="1.000000" stroke="none" 
	d="
M76.448013,89.996872 
	C76.002960,87.352150 76.236923,84.688377 75.950050,82.081932 
	C75.419014,77.257057 77.403236,75.359390 82.137650,75.980087 
	C83.434425,76.150093 84.772186,76.007545 86.545654,76.475922 
	C86.998177,81.629868 86.996193,86.316307 86.994202,91.002747 
	C83.626823,90.669724 80.259438,90.336700 76.448013,89.996872 
z"/>
<path fill="#FBECEA" opacity="1.000000" stroke="none" 
	d="
M87.181198,91.255547 
	C86.996193,86.316307 86.998177,81.629868 86.999931,76.478958 
	C87.382072,75.998276 87.764442,75.982071 88.433861,75.966049 
	C88.827843,80.977661 88.934776,85.989090 89.099281,91.458336 
	C88.560631,91.780228 87.964409,91.644287 87.181198,91.255547 
z"/>
<path fill="#FBECEA" opacity="1.000000" stroke="none" 
	d="
M88.809975,73.747253 
	C88.645233,73.727539 88.550629,73.462212 88.596252,73.104141 
	C88.784348,73.174805 88.832230,73.338226 88.809975,73.747253 
z"/>
<path fill="#FCE5E1" opacity="1.000000" stroke="none" 
	d="
M84.114029,65.581902 
	C84.181076,65.386559 84.339966,65.326424 84.742592,65.340591 
	C84.986328,65.414894 84.666107,65.757187 84.666107,65.757187 
	C84.666107,65.757187 84.205887,65.717102 84.114029,65.581902 
z"/>
<path fill="#FCE5E1" opacity="1.000000" stroke="none" 
	d="
M87.683517,65.371353 
	C87.778488,65.693748 87.581985,65.849770 87.150284,65.999199 
	C86.967506,66.058617 86.914429,65.737061 86.930069,65.574860 
	C87.111969,65.365433 87.278229,65.318207 87.683517,65.371353 
z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
